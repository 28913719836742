import { FormGroup, Grid, Button, MenuItem, InputAdornment, Typography } from '@material-ui/core';
import { CustomInput, TimePicker } from '@/components/FormItems';
import { ProjectIcon, TaskIcon } from '@/components/Icons';
import DeveloperModeIcon from '@material-ui/icons/DeveloperMode';
import { tasksOptions, hours, minutes, UserPositionsEnum } from '@/constants';
import clsx from 'clsx';
import { workModes } from './constants';
import useReportForm from './useReportForm';
import useStyles from './styles';
import { IReportFormProps } from './types';

const ReportForm = ({
  report,
  handleClose,
  onSubmitHandler,
  setReport,
  user,
  onSuccessDelete,
  ...props
}: IReportFormProps): JSX.Element => {
  const classes = useStyles();
  const tasks = tasksOptions[user?.position ?? UserPositionsEnum.developer];

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    projects,
    setFieldValue,
    deleteReportHandler,
    reportsLoading,
  } = useReportForm(report, onSubmitHandler, onSuccessDelete, setReport, handleClose);

  return (
    <div {...props}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {!report && (
            <Typography
              component="h2"
              variant="h1"
              className={report ? classes.editTitle : classes.title}
            >
              Create a report
            </Typography>
          )}
          <Grid item xs={12}>
            <FormGroup>
              <CustomInput
                select
                variant="standard"
                id="project"
                name="project"
                placeholder="Project"
                multiline
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.reportInput}
                value={values?.project ?? ''}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value: unknown) =>
                    !value ? (
                      <Typography style={{ opacity: 0.5 }}>Select your project</Typography>
                    ) : (
                      <>{projects.find((item) => item.id === value)?.projectName}</>
                    ),
                  MenuProps: {
                    placeholder: 'Project',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    anchorReference: 'anchorEl',
                    getContentAnchorEl: null,
                    transformOrigin: {
                      horizontal: 'center',
                      vertical: 'top',
                    },
                    classes: { paper: classes.menu },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <ProjectIcon viewBox="0, 0, 24, 24" />
                    </InputAdornment>
                  ),
                }}
                error={touched.project && !!errors?.project}
                helperText={touched.project && errors?.project}
              >
                {projects.map((project) => (
                  <MenuItem value={project.id} key={project.id}>
                    {project.projectName}
                  </MenuItem>
                ))}
              </CustomInput>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <CustomInput
                select
                variant="standard"
                id="taskType"
                name="taskType"
                placeholder="Project"
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.reportInput}
                value={values?.taskType ?? ''}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value: unknown) =>
                    !value ? (
                      <Typography style={{ opacity: 0.5 }}>Choose the task type</Typography>
                    ) : (
                      <>{value}</>
                    ),
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    anchorReference: 'anchorEl',
                    getContentAnchorEl: null,
                    transformOrigin: {
                      horizontal: 'center',
                      vertical: 'top',
                    },
                    classes: { paper: classes.menu },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <TaskIcon />
                    </InputAdornment>
                  ),
                }}
                error={touched.taskType && !!errors?.taskType}
                helperText={touched.taskType && errors?.taskType}
              >
                {tasks.map((task) => (
                  <MenuItem value={task} key={task}>
                    {task}
                  </MenuItem>
                ))}
              </CustomInput>
            </FormGroup>
          </Grid>

          <Grid item xs={12}>
            <FormGroup>
              <CustomInput
                select
                variant="standard"
                id="workMode"
                name="workMode"
                placeholder={workModes[0].label}
                onChange={handleChange}
                onBlur={handleBlur}
                className={classes.reportInput}
                value={values?.workMode ?? ''}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: (value: unknown) =>
                    !value ? (
                      <Typography style={{ opacity: 0.5 }}>Select the work mode</Typography>
                    ) : (
                      <>{workModes.find((item) => item.value === value)?.label}</>
                    ),
                  MenuProps: {
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    anchorReference: 'anchorEl',
                    getContentAnchorEl: null,
                    transformOrigin: {
                      horizontal: 'center',
                      vertical: 'top',
                    },
                    classes: { paper: classes.menu },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <DeveloperModeIcon />
                    </InputAdornment>
                  ),
                }}
                error={touched.workMode && !!errors?.workMode}
                helperText={touched.workMode && errors?.workMode}
              >
                {workModes.map(({ label, value }) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </CustomInput>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <TimePicker
                fullWidth
                variant="standard"
                id="workerdHours"
                name="workerdHours"
                onChange={handleChange}
                hours={hours}
                minutes={minutes}
                onBlur={handleBlur}
                inputClass={clsx(classes.reportInput, classes.timePicker)}
                value={values?.workerdHours ?? ''}
                error={touched.workerdHours && !!errors?.workerdHours}
                helperText={touched.workerdHours && errors?.workerdHours}
                onMinuteChange={handleChange}
                minuteValue={values?.workedMinutes ?? ''}
                hourValue={values?.workerdHours ?? ''}
                minuteInputName="workedMinutes"
                minuteInputId="workedMinutes"
                handleTimeChange={setFieldValue}
                FormHelperTextProps={{
                  className: classes.helperText,
                }}
                disabled
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <CustomInput
                id="description"
                name="description"
                placeholder="Add a description"
                onChange={handleChange}
                multiline
                onBlur={handleBlur}
                className={clsx(classes.reportInput, classes.descriptionInput)}
                value={values?.description ?? ''}
                error={touched.description && !!errors?.description}
                helperText={touched.description && errors?.description}
              />
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <div className={classes.buttonsBlock}>
              {report && (
                <Button
                  variant="outlined"
                  onClick={() => deleteReportHandler(report.id)}
                  classes={{
                    root: classes.delteButton,
                    label: classes.buttonLabel,
                  }}
                  disabled={report.taskType === 'Compensation'}
                >
                  Delete
                </Button>
              )}

              <Button
                variant="contained"
                type="submit"
                classes={{
                  root: classes.button,
                  label: classes.buttonLabel,
                }}
                disabled={reportsLoading || report?.taskType === 'Compensation'}
              >
                {report ? 'Edit' : 'Create'}
              </Button>
              <Button
                variant="outlined"
                onClick={handleClose}
                color="default"
                classes={{
                  root: classes.cancelButton,
                  label: classes.buttonLabel,
                }}
                disabled={reportsLoading}
              >
                Cancel
              </Button>
            </div>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default ReportForm;
