import { Box, Tooltip, Typography } from '@material-ui/core';
import StatisticCard from '@/components/Cards/StatisticCard';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { useSelector } from 'react-redux';
import { blurSelector } from '@/redux';
import clsx from 'clsx';
import useTaxesCard from './useTaxesCard';
import useStyles from './styles';

const TaxesCard = (): JSX.Element => {
  const styles = useStyles();
  const { expectedTaxes, preciseTaxAmountUah, targetDateStr, helpMessage, previousQuarterString } =
    useTaxesCard();

  const isBlured = useSelector(blurSelector);

  return (
    <StatisticCard
      title="Taxes"
      expected={
        <>
          <span className={clsx({ [styles.blured]: isBlured })}>${expectedTaxes} </span>
          <Tooltip title={helpMessage}>
            <HelpOutlineIcon className={styles.helpIcon} />
          </Tooltip>
        </>
      }
      overtimeTitle="Tax payment due date"
      overtimeValue={targetDateStr}
    >
      <Box>
        {preciseTaxAmountUah && (
          <>
            <Typography>Exact amount to be paid for {previousQuarterString}:</Typography>
            <Typography
              component="span"
              className={clsx(styles.value, { [styles.blured]: isBlured })}
            >
              {preciseTaxAmountUah}
              <Box component="span" className={styles.label}>
                UAH
              </Box>
            </Typography>
          </>
        )}
      </Box>
    </StatisticCard>
  );
};

export default TaxesCard;
