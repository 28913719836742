import { userStateSelector } from '@/redux';
import { Avatar, Divider, Typography } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { getHolidays } from '@/utils';
import { format } from 'date-fns';
import useStyles from './styles';

const TimeOffsUserHeader: React.FC = () => {
  const classes = useStyles();

  const currentUser = useSelector(userStateSelector);

  const holidays = useMemo(() => getHolidays(new Date().getFullYear()), []);

  return (
    <div className={classes.root}>
      <div className={classes.userBlock}>
        <Avatar
          alt={currentUser?.fullName}
          src={currentUser?.photo ?? '/src/assets/svg/logo.svg'}
          className={classes.avatar}
        />
        <Typography>{currentUser?.fullName}</Typography>
      </div>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div>
        <Typography style={{ fontSize: 16, fontWeight: 600 }}>
          Total paid: {currentUser?.vacationsDetail.total}
        </Typography>
        <Typography style={{ fontSize: 12 }}>
          From 01.01 to 30.06: {currentUser?.vacationsDetail.firstHalfYearLeft ?? 0} out of{' '}
          {currentUser?.vacationsDetail.firstHalfYearTotal}
        </Typography>
        <Typography style={{ fontSize: 12 }}>
          From 01.07 to 31.12: {currentUser?.vacationsDetail.secondHalfYearLeft ?? 0} out of{' '}
          {currentUser?.vacationsDetail.secondHalfYearTotal}
        </Typography>
      </div>

      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div>
        <Typography style={{ fontSize: 16, fontWeight: 600 }}>
          Sick Leaves: {currentUser?.sickLeavesDetail.total}
        </Typography>
        <Typography style={{ fontSize: 12 }}>
          Paid: {currentUser?.sickLeavesDetail.paidLeft ?? 0} out of{' '}
          {currentUser?.sickLeavesDetail.paidTotal}
        </Typography>
        <Typography style={{ fontSize: 12 }}>
          Unpaid: {currentUser?.sickLeavesDetail.unpaidLeft ?? 0} out of{' '}
          {currentUser?.sickLeavesDetail.unpaidTotal}
        </Typography>
      </div>
      <Divider className={classes.divider} orientation="vertical" flexItem />
      <div>
        <Typography style={{ fontSize: 16, fontWeight: 600 }}>
          This year paid holidays: 4
        </Typography>
        <Typography style={{ fontSize: 12 }}>
          {holidays.map((item, index) => (
            <span key={index}>
              {format(item.date, 'dd.MM.yyyy')}; {index % 2 > 0 ? <br /> : ''}{' '}
            </span>
          ))}
        </Typography>
      </div>
    </div>
  );
};

export default TimeOffsUserHeader;
