import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import { ISimpleStatisticCardProps } from './types';

const SimpleStatisticCard = ({
  children,
  className,
  contentClassName,
}: ISimpleStatisticCardProps): JSX.Element => {
  const styles = useStyles();

  return (
    <Card className={clsx(styles.root, className)}>
      <CardContent className={clsx(styles.content, contentClassName)}>{children}</CardContent>
    </Card>
  );
};

export default SimpleStatisticCard;
