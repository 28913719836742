import { Box, Card, CardContent, Typography } from '@material-ui/core';
import clsx from 'clsx';
import useStyles from './styles';
import { IStatisticCardProps } from './types';

const StatisticCard = ({
  title,
  expected,
  badge,
  overtimeTitle,
  overtimeValue,
  children,
  className,
  contentClassName,
}: IStatisticCardProps): JSX.Element => {
  const styles = useStyles();
  const shouldDisplayOvertimeInfo = !!overtimeTitle || !!overtimeValue;

  return (
    <Card className={clsx(styles.root, className)}>
      <Box className={clsx(styles.header, styles.flexSpaceBetween)}>
        <Typography component="h4" className={styles.title}>
          {title}
        </Typography>
        {expected && (
          <Box className={styles.flexCenter}>
            <Typography component="span" className={clsx(styles.title, styles.subTitle)}>
              Expected:
            </Typography>
            <Typography component="span" className={clsx(styles.title)}>
              {expected}
            </Typography>
          </Box>
        )}
        {badge && <Box className={styles.flexCenter}>{badge}</Box>}
      </Box>
      <CardContent className={clsx(styles.content, contentClassName)}>
        {children ? <Box className={styles.flexSpaceBetween}>{children}</Box> : null}
        {shouldDisplayOvertimeInfo && (
          <Box className={clsx(styles.flexSpaceBetween, styles.overtimeBox)}>
            <Typography component="span" className={styles.overtimeTitle}>
              {overtimeTitle}
            </Typography>
            <Typography component="span" className={styles.overtimeValue}>
              {overtimeValue}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default StatisticCard;
