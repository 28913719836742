import { string, object, date, ref } from 'yup';
import { isValid, isSameYear, eachDayOfInterval } from 'date-fns';
import pluralize from 'pluralize';
import { IAuthUser } from '@/types/user';
import { VacationType, VacationPaymentType } from '@/types/vacation';
import { getYearLefts, isWorkDay } from '@/utils';

const vacationSchema = (user: IAuthUser | null) =>
  object().shape({
    startDate: date()
      .typeError('Invalid Date')
      .required('The field Date is required')
      .test((value, context) => {
        if (value && isValid(value) && context.parent.endDate && isValid(context.parent.endDate)) {
          if (!isSameYear(context.parent.endDate, new Date())) {
            return context.createError({
              message: "Date can't be other than the current year",
            });
          }

          const days = eachDayOfInterval({ start: value, end: context.parent.endDate }).filter(
            isWorkDay,
          );

          if (
            days.length > 10 &&
            [VacationType.Vacation, VacationType.SickLeave].includes(context.parent.type)
          ) {
            return context.createError({ message: 'The max allowed duration is set at 10 days.' });
          }

          if (days.length > 1 && [VacationType.DayOff].includes(context.parent.type)) {
            return context.createError({ message: 'The max allowed duration is set at 1 day.' });
          }

          if (
            [VacationType.Vacation, VacationType.DayOff].includes(context.parent.type) &&
            context.parent.paymentType === VacationPaymentType.Paid
          ) {
            const yearLefts = getYearLefts(days);
            if (
              yearLefts.first > (user?.vacationsDetail.firstHalfYearLeft ?? 0) ||
              yearLefts.second > (user?.vacationsDetail.secondHalfYearLeft ?? 0)
            )
              return context.createError({
                message: `You have ${user?.vacationsDetail.firstHalfYearLeft ?? 0} ${pluralize(
                  'day',
                  user?.vacationsDetail.firstHalfYearLeft ?? 0,
                )} left in the first half and ${
                  user?.vacationsDetail.secondHalfYearLeft ?? 0
                } ${pluralize(
                  'day',
                  user?.vacationsDetail.secondHalfYearLeft ?? 0,
                )} in the second half of the year.`,
              });
          }
          if ([VacationType.SickLeave].includes(context.parent.type)) {
            if (
              context.parent.paymentType === VacationPaymentType.Paid &&
              days.length > (user?.sickLeavesDetail.paidLeft ?? 0)
            ) {
              return context.createError({
                message: `You only have ${user?.sickLeavesDetail.paidLeft ?? 0} ${pluralize(
                  'day',
                  user?.sickLeavesDetail.unpaidLeft ?? 0,
                )} of paid sick leave left.`,
              });
            }

            if (
              context.parent.paymentType === VacationPaymentType.SelfPaid &&
              days.length > (user?.sickLeavesDetail.unpaidLeft ?? 0)
            ) {
              return context.createError({
                message: `You only have ${user?.sickLeavesDetail.unpaidLeft ?? 0} ${pluralize(
                  'day',
                  user?.sickLeavesDetail.unpaidLeft ?? 0,
                )} of unpaid sick leave left.`,
              });
            }
          }
        }

        return true;
      }),
    endDate: date()
      .typeError('Invalid Date')
      .min(ref('startDate'), "End date can't be before Start date")
      .required('The field Date is required'),
    type: string().required('The field Type is required'),
    comment: string().max(500).required('The field Comment is required'),
    paymentType: string(),
  });

export default vacationSchema;
