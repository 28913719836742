import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Tooltip,
  IconButton,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { DateFormatUtil, camelCaseToWords } from '@/utils';
import { TrashIcon } from '@/components/Icons';
import { useMemo } from 'react';
import useCanRejectVacations from '@/hooks/useCanRejectVacations';
import { IVacationTableProps } from './types';
import useStyles from '../styles';
import useLocalStyles from './styles';

const tableHead = [
  { name: 'Submitted date', align: 'left', numeric: false, id: 'fullName' },
  { name: 'Period', align: 'left', numeric: false, id: 'date' },
  { name: 'Duration', align: 'left', numeric: false, id: 'Duration' },
  { name: 'Type', align: 'left', numeric: false, id: 'type' },
  { name: 'Payment Type', align: 'left', numeric: false, id: 'paymentType' },
  { name: 'Details', align: 'left', numeric: false, id: 'description' },
  { name: 'Status', align: 'center', numeric: false, id: 'status' },
  { name: 'Actions', align: 'center', numeric: false, id: 'actions' },
] as const;

export default function VacationTable({
  vacationsDetails,
  selectedYear,
  onCancelVacation,
  ...props
}: IVacationTableProps): JSX.Element {
  const classes = useStyles();
  const localClasses = useLocalStyles();
  const canRejectVacation = useCanRejectVacations();

  const reversedVacationDetails = useMemo(
    () => [...vacationsDetails].reverse(),
    [vacationsDetails],
  );

  return (
    <div className={classes.mainContent} {...props}>
      <Box className={localClasses.vacationTableHeader}>
        <Typography variant="h3">Time off history</Typography>
      </Box>
      <Card className={classes.card}>
        <CardContent>
          <TableContainer component={Paper} className={classes.tableWrap}>
            <Table className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  {tableHead.map((el) => (
                    <TableCell key={el.id} align={el.align} className={classes.tableHeader}>
                      {el.name}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {reversedVacationDetails.map((row, idx) => (
                  <TableRow key={idx}>
                    <TableCell className={classes.tableItem}>
                      {DateFormatUtil.formatToDate(row.startDate)}
                    </TableCell>
                    <TableCell className={classes.tableItem}>
                      {DateFormatUtil.formatToDate(row.startDate)} -{' '}
                      {DateFormatUtil.formatToDate(row.endDate)}
                    </TableCell>
                    <TableCell className={classes.tableItem}>{row.duration}</TableCell>
                    <TableCell className={classes.tableItem}>{row.type}</TableCell>
                    <TableCell className={classes.tableItem}>
                      {camelCaseToWords(row?.paymentType ?? '')}
                    </TableCell>
                    <TableCell className={classes.tableItem}>{row.details}</TableCell>
                    <TableCell className={classes.tableItem}>
                      <div
                        className={clsx(
                          classes.status,
                          row.status === 'Rejected'
                            ? classes.rejected
                            : classes[row.status === 'Approved' ? 'approved' : 'pending'],
                        )}
                      >
                        {row.status}
                      </div>
                    </TableCell>
                    <TableCell align="center" className={clsx(classes.tableItem)}>
                      {canRejectVacation(row) ? (
                        <Tooltip
                          title={
                            row.status === 'Pending' ? 'Cancel vacation request' : 'Cancel vacation'
                          }
                        >
                          <IconButton onClick={() => onCancelVacation(row)}>
                            <TrashIcon />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <IconButton disabled>
                          <TrashIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>
    </div>
  );
}
