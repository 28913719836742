import { Box, Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { blurSelector } from '@/redux';
import clsx from 'clsx';
import useStyles from '../../styles';
import type { IOvertimeCardProps } from './types';

const OvertimeCard = ({ user }: IOvertimeCardProps): JSX.Element => {
  const styles = useStyles();

  const overTime = useMemo(() => user?.overTimePercent ?? 0, [user?.overTimePercent]);
  const isOverTime = useMemo(() => overTime > 0, [overTime]);

  const isBlurred = useSelector(blurSelector);

  return (
    <Box>
      <Typography className={styles.title} component="h4">
        Overtime
      </Typography>
      <Box className={styles.box}>
        {isOverTime ? (
          <Box>
            <Typography className={styles.text} component="span">
              Current:&nbsp;
            </Typography>
            <Typography
              className={clsx(styles.sup, { [styles.blured]: isBlurred })}
              component="span"
            >
              {overTime}%
            </Typography>
          </Box>
        ) : (
          <Typography className={styles.text} component="span">
            Disallowed
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default OvertimeCard;
