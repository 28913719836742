import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      boxShadow: 'none',
      padding: theme.spacing(1),
      background: theme.palette.task.development,
      borderRadius: 0,
      [theme.breakpoints.up('md')]: {
        borderRadius: 12,
      },
    },
    content: {
      background: theme.palette.background.paper,
      borderRadius: 12,
    },
    blured: {
      filter: `blur(10px)`,
    },
  }),
);

export default useStyles;
