import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  horizontal: {
    marginLeft: 'auto',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    flexDirection: 'row',
    marginBottom: '20px',
  },
  vertical: {
    maxWidth: '300px',
    marginLeft: 'auto',
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(3),
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  totalBlock: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 'bold',
    textAlign: 'right',
  },
  total: {
    display: 'flex',
    marginTop: '10px',
    maxWidth: '212px',
    marginRight: '50px',
  },
  item: {
    display: 'flex',
    width: '100%',
    marginTop: '10px',
    maxWidth: '250px',
    marginRight: '32px',
  },
  itemTitle: {
    textAlign: 'right',
    maxWidth: 120,
    width: '100%',
    marginRight: 12,
  },
  itemValue: {
    textAlign: 'center',
    position: 'relative',
    width: '100%',

    '&:before': {
      content: '""',
      position: 'absolute',
      bottom: -4,
      left: 0,
      width: '100%',
      height: '1px',
      backgroundColor: theme.palette.grey[100],
    },
  },
  itemValueNotBordered: {
    textAlign: 'center',
    position: 'relative',
    width: '100%',
  },
  blured: { filter: `blur(10px)` },
}));

export default useStyles;
