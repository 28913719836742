import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cell: {
      padding: theme.spacing(0),
      border: 'none',
      '&:hover': {
        backgroundColor: 'rgba(84, 53, 184, 0.1)',
        cursor: 'pointer',
      },
      [theme.breakpoints.up('md')]: {
        height: '8rem',
        width: '8rem',
        border: '1px solid rgba(224, 224, 224, 1)',
      },
    },
    addIcon: {
      cursor: 'pointer',
      zIndex: 100,
      position: 'absolute',
      minWidth: '24px',
      width: '30px',
      marginLeft: '-46px',
    },
    editIcon: {
      cursor: 'pointer',
      zIndex: 100,
      position: 'absolute',
      minWidth: '24px',
      width: '30px',
      bottom: 0,
      right: 14,
    },
    disabledCell: {
      color: theme.palette.text.disabled,
      backgroundColor: theme.palette.background.default,
      cursor: 'not-allowed',
    },
    notClickable: {
      '&:hover': {
        backgroundColor: theme.palette.background.default,
        cursor: 'not-allowed',
      },
    },
    cellButton: {
      height: '100%',
      width: '100%',
      alignItems: 'start',
      position: 'relative',
      borderRadius: 0,
      justifyContent: 'center',
      [theme.breakpoints.up('md')]: {
        justifyContent: 'initial',
      },
    },
    content: {
      width: 'min-content',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
    todayShape: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
      width: '24px',
      height: '20px',
      borderRadius: '50%',
      fontSize: '14px',
      lineHeight: '19px',
      letterSpacing: '0.16px',
      [theme.breakpoints.up('md')]: {
        height: '24px',
      },
    },
    cellHeader: {
      padding: theme.spacing(1, 1),
    },
    cellBody: {
      padding: theme.spacing(0, 1),
    },
    contentBadge: {
      width: '8px',
      height: '8px',
      borderRadius: '50%',
      backgroundColor: '#FB9C3F',
    },
  }),
);

export default useStyles;
