import { enGBLocale } from '@/constants';
import { DesktopDateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { TextField, Grid } from '@mui/material';
import useStyles from './styles';
import { RangeDatePickerProps } from './types';

export default function VacationRangeDatePicker({
  date,
  setDate,
  componentChildren,
  startError,
  endError,
  startOnBlur,
  endOnBlur,
  disabled,
}: RangeDatePickerProps): JSX.Element {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <LocalizationProvider locale={enGBLocale} dateAdapter={AdapterDateFns}>
        <DesktopDateRangePicker
          startText="From"
          endText="To"
          allowSameDateSelection={false}
          PopperProps={{
            className: classes.popper,
          }}
          PaperProps={{
            className: classes.paperContent,
          }}
          className={classes.paperContent}
          value={date}
          onChange={setDate}
          disabled={disabled}
          inputFormat="dd.MM.yyyy"
          mask="__.__.____"
          renderInput={(startProps, endProps) => (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  {...startProps}
                  name="startDate"
                  error={!!startError}
                  onBlur={startOnBlur}
                  helperText={startError ?? ''}
                  className={classes.dateTextField}
                />
              </Grid>

              <Grid item xs={6}>
                <TextField
                  {...endProps}
                  name="endDate"
                  error={!!endError}
                  onBlur={endOnBlur}
                  helperText={endError ?? ''}
                  className={classes.dateTextField}
                />
              </Grid>
            </Grid>
          )}
        />
      </LocalizationProvider>
      {componentChildren}
    </div>
  );
}
