import { userSelector } from '@/redux';
import { Divider, Typography, useMediaQuery, Theme } from '@material-ui/core';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getHolidays } from '@/utils';
import { useMemo } from 'react';
import { format } from 'date-fns';
import useStyles from './styles';

const TimeOffsProfileHeader: React.FC = () => {
  const classes = useStyles();
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const user = useSelector(userSelector);

  const holidays = useMemo(() => getHolidays(new Date().getFullYear()), []);
  return (
    <div>
      {isDesktop ? (
        <div className={classes.root}>
          <div>
            <Typography className={classes.text}>
              Total paid: {user?.vacationsDetail.total}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              From 01.01 to 30.06: {user?.vacationsDetail.firstHalfYearLeft ?? 0} out of{' '}
              {user?.vacationsDetail.firstHalfYearTotal}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              From 01.07 to 31.12: {user?.vacationsDetail.secondHalfYearLeft ?? 0} out of{' '}
              {user?.vacationsDetail.secondHalfYearTotal}
            </Typography>
          </div>
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <div>
            <Typography className={classes.text}>
              Sick Leaves: {user?.sickLeavesDetail.total}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              Paid: {user?.sickLeavesDetail.paidLeft ?? 0} out of {user?.sickLeavesDetail.paidTotal}
            </Typography>
            <Typography style={{ fontSize: 12 }}>
              Unpaid: {user?.sickLeavesDetail.unpaidLeft ?? 0} out of{' '}
              {user?.sickLeavesDetail.unpaidTotal}
            </Typography>
          </div>
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <div>
            <Typography className={classes.text}>This year paid holidays: 4</Typography>
            <Typography style={{ fontSize: 12 }}>
              {holidays.map((item, index) => (
                <span key={index}>
                  {format(item.date, 'dd.MM.yyyy')}; {index % 2 > 0 ? <br /> : ''}{' '}
                </span>
              ))}
            </Typography>
          </div>
        </div>
      ) : (
        <div className={classes.container}>
          <div className={classes.block}>
            <Typography className={clsx(classes.text, classes.title, classes.mobileText)}>
              Total paid: {user?.vacationsDetail.total}
            </Typography>
            <Typography className={clsx(classes.text, classes.mobileText)}>
              From 01.01 to 30.06: {user?.vacationsDetail.firstHalfYearLeft ?? 0} out of{' '}
              {user?.vacationsDetail.firstHalfYearTotal}
              <br />
              From 01.07 to 31.12: {user?.vacationsDetail.secondHalfYearLeft ?? 0} out of{' '}
              {user?.vacationsDetail.secondHalfYearTotal}
            </Typography>
          </div>
          <div className={classes.block}>
            <Typography className={clsx(classes.text, classes.title, classes.mobileText)}>
              Sick Leaves: {user?.sickLeavesDetail.total}
            </Typography>
            <Typography className={clsx(classes.text, classes.mobileText)}>
              Paid: {user?.sickLeavesDetail.paidLeft ?? 0} out of {user?.sickLeavesDetail.paidTotal}
              <br />
              Unpaid: {user?.sickLeavesDetail.unpaidLeft ?? 0} out of{' '}
              {user?.sickLeavesDetail.unpaidTotal}
            </Typography>
          </div>
        </div>
      )}
    </div>
  );
};

export default TimeOffsProfileHeader;
