import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Grid, Card, Typography } from '@material-ui/core';
import { ProfileForm, ChangePasswordForm } from '@/components/Forms';
import { UserCard, InfoCard, MobileUserCard, ContractsCard } from '@/components/Cards';
import Header from '@/components/Header';
import Button from '@/components/Buttons/Button';
import ProfileDialog from '@/components/MobileDialogs/ProfileDialog';
import useProfile from './useProfile';
import useStyles from './styles';

const Profile: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  const {
    userProfileInfo,
    user,
    userInfoData,
    handleAvatarChange,
    isDesktop,
    setShowProfileDialg,
    durationTime,
    logOutHandler,
    showProfileDialg,
    handleDialogClose,
  } = useProfile();

  return (
    <Grid container className={classes.content} spacing={isDesktop ? 3 : 0}>
      {isDesktop ? (
        <>
          <Grid item xs={8} md={7}>
            <Card className={classes.card}>
              <Typography className={classes.title} component="h1">
                Profile
              </Typography>
              <ProfileForm user={userProfileInfo} />
              <Typography className={classes.secondTitle} component="h4">
                Change password
              </Typography>
              <ChangePasswordForm />
            </Card>
          </Grid>
          <Grid item xs={3} md={5}>
            <UserCard user={user} handleAvatarChange={handleAvatarChange} />
            <InfoCard data={userInfoData} />
            <ContractsCard className={classes.card} />
          </Grid>
        </>
      ) : (
        <div className={classes.infoBlock}>
          <div className={classes.container}>
            <Header className={classes.headerStyle} title="Profile" />
            <Button className={classes.headerButton} onClick={() => setShowProfileDialg(true)}>
              Edit profile
            </Button>
          </div>
          <MobileUserCard user={user} durationTime={durationTime} />
          <Button className={classes.logOut} onClick={logOutHandler}>
            Log Out
          </Button>
        </div>
      )}
      <ProfileDialog
        user={userProfileInfo}
        userInfo={user}
        openDialog={showProfileDialg}
        handleDialogClose={handleDialogClose}
        handleAvatarChange={handleAvatarChange}
      />
    </Grid>
  );
};

export default Profile;
