import { Box, Typography } from '@material-ui/core';
import CircularProgress from '@/components/CircularProgress';
import { useSelector } from 'react-redux';
import { blurSelector } from '@/redux';
import clsx from 'clsx';
import type { IHoursCardProps } from './types';
import useStyles from '../../styles';
import useHoursCard from './useHoursCard';

const HoursCard = ({ user }: IHoursCardProps): JSX.Element => {
  const styles = useStyles();
  const { time, expected, progress } = useHoursCard(user);
  const isBlurred = useSelector(blurSelector);

  return (
    <Box>
      <Typography className={styles.title} component="h4">
        Hours
      </Typography>
      <Box className={styles.box}>
        <Box>
          <Box className={styles.boxWrap}>
            <Typography className={styles.text} component="span">
              Current:&nbsp;
            </Typography>
            <Typography
              className={clsx(styles.sup, { [styles.blured]: isBlurred })}
              component="span"
            >
              {time.h}&nbsp;hours&nbsp;
            </Typography>
            <Typography
              className={clsx(styles.sup, { [styles.blured]: isBlurred })}
              component="span"
            >
              {time.m}&nbsp;min&nbsp;
            </Typography>
          </Box>
          <Box className={clsx(styles.textMute, styles.boxWrap)}>
            <Typography className={styles.text} component="span">
              Expected:&nbsp;
            </Typography>
            <Typography
              className={clsx(styles.text, { [styles.blured]: isBlurred })}
              component="span"
            >
              {expected} hours
            </Typography>
          </Box>
        </Box>
        <Box className={styles.progressBar}>
          <CircularProgress value={progress} size={52} />
        </Box>
      </Box>
    </Box>
  );
};

export default HoursCard;
