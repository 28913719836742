import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { loadUserOverallStatistic, statisticSelector } from '@/redux';

const useContractsCard = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserOverallStatistic({ type: 'month' }));
  }, [dispatch]);

  const {
    contractStatus,
    contractExpirationDate,
    upcomingContractStartDate,
    upcomingContractEndDate,
  } = useSelector(statisticSelector);

  return {
    contractStatus,
    contractExpirationDate,
    upcomingContractStartDate,
    upcomingContractEndDate,
  };
};

export default useContractsCard;
