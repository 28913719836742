import { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  loadUserProfile,
  userSelector,
  createVacation,
  allEventsSelector,
  loadUserVacationsDetails,
  vacationsDetailsSelector,
  loadAllEvents,
  IVacationDetails,
} from '@/redux';
import { IVacationData } from '@/interfaces/app/entities/vacation.interface';
import { useMediaQuery, Theme } from '@material-ui/core';
import { FormikHelpers } from 'formik';
import { endOfYear, format, startOfYear } from 'date-fns';
import { DateRange } from '@mui/lab';
import { RangeModifier } from 'react-day-picker';
import logError from '@/utils/errors';
import timeOffsService from '@/services/timeOffs.service';

const useTimeOff = () => {
  const dispatch = useDispatch();

  const [vacationToReject, setVacationToReject] = useState<IVacationDetails | null>(null);
  const user = useSelector(userSelector);
  const events = useSelector(allEventsSelector);
  const vacationsDetails = useSelector(vacationsDetailsSelector);
  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const [eventDate, setEventDate] = useState<RangeModifier>({
    from: undefined,
    to: undefined,
  });
  const [openDialog, setOpenDialog] = useState(false);

  const data = useMemo(
    () => ({
      date: eventDate,
      isAdmin: false,
    }),
    [eventDate],
  );

  useEffect(() => {
    dispatch(loadUserProfile());
    dispatch(loadAllEvents(data));
    if (user?.id) {
      dispatch(
        loadUserVacationsDetails({
          from: data.date.from ? startOfYear(data.date.from) : undefined,
          to: data.date.from ? endOfYear(data.date.from) : undefined,
        }),
      );
    }
  }, [data, dispatch, user?.id]);

  const [openVacationModal, setOpenVacationModal] = useState(false);

  const toggleModal = (value: boolean) => {
    setOpenVacationModal(!value);
  };

  const toggleDialog = () => {
    setOpenDialog((value) => !value);
  };

  const [date, setDate] = useState<DateRange<Date | null>>([null, null]);

  const onSubmitHandler = useCallback(
    (values: IVacationData, { resetForm }: FormikHelpers<IVacationData>) => {
      dispatch(
        createVacation({
          ...values,
          startDate: date[0] ? format(new Date(date[0]), 'yyyy-MM-dd') : undefined,
          endDate: date[1] ? format(new Date(date[1]), 'yyyy-MM-dd') : undefined,
          ...data,
        }),
      );
      dispatch(loadAllEvents(data));
      setOpenDialog(false);
      setOpenVacationModal(false);
      resetForm();
      setDate([null, null]);
    },
    [data, date, dispatch],
  );

  const closeModal = () => {
    setOpenVacationModal((value) => !value);
  };

  const onCancelVacation = (vacation: IVacationDetails) => {
    // reject vacation by user
    // by showing confirmation modal
    setVacationToReject(vacation);
  };
  const onConfirmCancelVacation = async (vacation: IVacationDetails) => {
    try {
      await timeOffsService.cancelVacationRequestByUser(vacation.id);
    } catch (e) {
      logError(e);
      throw e;
    }

    dispatch(loadUserProfile());
    dispatch(loadAllEvents(data));
    if (user?.id) {
      dispatch(
        loadUserVacationsDetails({
          from: data.date.from ? startOfYear(data.date.from) : undefined,
          to: data.date.from ? endOfYear(data.date.from) : undefined,
        }),
      );
    }
    setVacationToReject(null);
  };

  const closeRejectVacationModal = () => {
    setVacationToReject(null);
  };

  const selectedYear = startOfYear(data.date.from ?? new Date()).getFullYear();
  return {
    openVacationModal,
    setOpenVacationModal,
    toggleModal,
    onSubmitHandler,
    setDate,
    date,
    events,
    user,
    vacationsDetails,
    setEventDate,
    isDesktop,
    toggleDialog,
    openDialog,
    closeModal,
    selectedYear,
    onCancelVacation,
    vacationToReject,
    closeRejectVacationModal,
    onConfirmCancelVacation,
  };
};

export default useTimeOff;
