import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '@/components/Buttons/Button';
import ReportsCalendar from '@/components/ReportsCalendar';
import { RouteComponentProps } from 'react-router-dom';
import { ProjectSelect } from '@/components/FormItems';
import { BackArrow } from '@/components/Icons';
import Header from '@/components/Header';
import DesktopRangeDatePicker from '@/components/DesktopRangeDatePicker';
import UserReportTable from '@/components/UserReportTable';
import userReport from './useReports';
import useStyles from './styles';

const Reports: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const {
    reports,
    handleDisplayChange,
    showCalendar,
    projects,
    defaultSelectedProjects,
    selectedProjects,
    setSelectedProjects,
    searchName,
    setSearchName,
    date,
    setDate,
    isDesktop,
    fetchResults,
    fetchProjects,
    user,
  } = userReport();

  return (
    <Grid container spacing={isDesktop ? 2 : 0}>
      <Grid item xs={12}>
        <Grid container spacing={isDesktop ? 2 : 0}>
          {isDesktop ? (
            <>
              <Grid item xs={8}>
                <Typography variant="h5" className={classes.title}>
                  Reports
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container justifyContent="flex-end">
                  <Button
                    size="medium"
                    variant="contained"
                    color="primary"
                    className={classes.allButton}
                    onClick={handleDisplayChange}
                  >
                    {showCalendar ? (
                      'All Reports'
                    ) : (
                      <>
                        <BackArrow /> Back
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </>
          ) : (
            <Header title="Reports" />
          )}
        </Grid>
        <Grid container spacing={isDesktop ? 2 : 0}>
          <Grid item xs={12}>
            {showCalendar ? (
              <>
                <ReportsCalendar user={user} reports={reports} />
              </>
            ) : (
              <>
                <div className={classes.datePicker}>
                  <DesktopRangeDatePicker date={date} setDate={setDate} />
                  <div className={classes.projectInputBlock}>
                    {defaultSelectedProjects ? (
                      <ProjectSelect
                        className={classes.projectInput}
                        id="projects"
                        variant="outlined"
                        label="Project"
                        name="projects"
                        data={projects}
                        selectedProjects={selectedProjects}
                        setSelectedProjects={setSelectedProjects}
                        searchName={searchName}
                        setSearchName={setSearchName}
                        fetchProjects={fetchProjects}
                        defaultValue={defaultSelectedProjects}
                      />
                    ) : null}
                  </div>
                </div>
                <UserReportTable fetchResults={fetchResults} reports={reports} />
              </>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Reports;
