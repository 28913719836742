import React from 'react';
import { Card, CardContent } from '@material-ui/core';
import clsx from 'clsx';
import NavButtonsGroup from '@/components/NavButtonsGroup';
import { MonthChanger, YearChanger } from '@/components/FormItems';
import { Dates } from '@/utils';
import ChartComponent from './Chart';
import { IRevenueCardProps } from '../types';
import useRevenueCard from './useRevenueCard';
import useStyles from './styles';

const RevenueCard = ({
  className,
  chartHeight,
  userCard,
  classes,
  overallSalary,
  userRole,
  isDesktop,
  period,
  year,
  month,
  prevMonth,
  nextMonth,
  prevYear,
  nextYear,
  changePeriod,
  ...props
}: IRevenueCardProps): JSX.Element => {
  const innerClasses = useStyles();

  const { data, totalEarning, totalPrevEarning, isVisible } = useRevenueCard(
    period,
    overallSalary,
    userRole,
    year,
    month,
    isDesktop,
  );

  return (
    <Card className={clsx(innerClasses.root, className)} {...props}>
      <CardContent className={innerClasses.content}>
        <div className={clsx(innerClasses.header)}>
          <div className={innerClasses.titleBlock}>
            <div className={clsx(innerClasses.title, classes?.title)}>Revenue</div>
            {period !== 'Week' && (
              <div className={innerClasses.revenueBlock}>
                <div className={innerClasses.current}>
                  <span className={innerClasses.target}>
                    {period === 'Month' ? Dates.getMonths(false)[month] : year}
                  </span>
                  <span className={clsx({ [innerClasses.blured]: !isVisible && isDesktop })}>
                    $ {totalEarning}
                  </span>
                </div>
                <div className={innerClasses.before}>
                  <span className={innerClasses.target}>
                    {period === 'Month' ? Dates.getMonths(false)[month - 1] : year - 1}
                  </span>
                  <span className={clsx({ [innerClasses.blured]: !isVisible && isDesktop })}>
                    $ {totalPrevEarning}
                  </span>
                </div>
              </div>
            )}
          </div>
          <div className={innerClasses.header}>
            {period === 'Year' && isDesktop && (
              <>
                <YearChanger date={year} setPrevDate={prevYear} setNextDate={nextYear} />
              </>
            )}
            {period === 'Month' && isDesktop && (
              <>
                <MonthChanger date={month} setPrevDate={prevMonth} setNextDate={nextMonth} />
              </>
            )}
            <NavButtonsGroup
              isDesktop={isDesktop}
              userCard
              state={period}
              setChanges={changePeriod}
            />
          </div>
        </div>
        <ChartComponent
          overallSalary={data}
          chartHeight={chartHeight}
          period={period}
          overallHours={null}
          isDesktop={isDesktop}
        />
      </CardContent>
    </Card>
  );
};
export default RevenueCard;
