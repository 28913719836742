import { useEffect } from 'react';
import { loadNextEvents, loadUserEvents, nextEventsSelector } from '@/redux';
import { useDispatch, useSelector } from 'react-redux';

const useUpcomingEvents = () => {
  const dispatch = useDispatch();
  const events = useSelector(nextEventsSelector);

  useEffect(() => {
    dispatch(loadUserEvents());
    dispatch(loadNextEvents());
  }, [dispatch]);

  return {
    events,
  };
};

export default useUpcomingEvents;
