export enum VacationType {
  SickLeave = 'Sick leave',
  Vacation = 'Vacation',
  DayOff = 'Day off',
}

export enum VacationStatus {
  Approved = 'Approved',
  Pending = 'Pending',
  Rejected = 'Rejected',
}

export enum VacationPaymentType {
  Paid = 'Paid',
  SelfPaid = 'SelfPaid',
}

export const vacationPaymentTypeOptions = [
  { label: 'Paid', value: VacationPaymentType.Paid },
  { label: 'Self Paid', value: VacationPaymentType.SelfPaid },
];

export const vacationTypeOptions = [
  { label: 'Day off', value: VacationType.DayOff },
  { label: 'Vacation', value: VacationType.Vacation },
  { label: 'Sick leave', value: VacationType.SickLeave },
];
