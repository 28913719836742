import auth from '@DevimaSolutions/o-auth';
import {
  ICreateVacation,
  IEditEvent,
  IEvent,
  ILoadUserEvents,
  IPendingVacation,
  IVacationDetails,
} from '@/redux';
import { AxiosResponse } from 'axios';
import { RangeModifier } from 'react-day-picker';
import { format } from 'date-fns';

const loadVacations = async (date?: RangeModifier): Promise<Record<string, IEvent[]>> => {
  const response = await auth()
    .axios.get(`/admin/vacations`, {
      ...(date?.from &&
        date?.to && {
          params: {
            from: format(new Date(date.from), 'yyyy-MM-dd'),
            to: format(new Date(date.to), 'yyyy-MM-dd'),
          },
        }),
    })
    .then(({ data }: AxiosResponse<Record<string, IEvent[]>>) => data);

  return response ?? {};
};

const loadNextEvents = async (): Promise<IEvent[]> => {
  const response = await auth()
    .axios.get(`user/event/actual?count=2`)
    .then(({ data }) => data);

  return response ?? {};
};
const loadVacationsByUser = async (payload: ILoadUserEvents): Promise<Record<string, IEvent[]>> => {
  const response = await auth()
    .axios.get(`/admin/vacations/`, {
      params: {
        userId: payload.userId,
        from: payload.date?.from,
        to: payload.date?.to,
      },
    })
    .then(({ data }: AxiosResponse<Record<string, IEvent[]>>) => data);

  return response ?? {};
};

const loadUserEvents = async (date?: RangeModifier): Promise<Record<string, IEvent[]>> => {
  const response = await auth()
    .axios.get('/user/event', {
      ...(date?.from &&
        date?.to && {
          params: {
            from: format(new Date(date.from), 'yyyy-MM-dd'),
            to: format(new Date(date.to), 'yyyy-MM-dd'),
          },
        }),
    })
    .then(({ data }: AxiosResponse<Record<string, IEvent[]>>) => data);

  return response ?? {};
};

const loadUserVacation = async (date?: RangeModifier): Promise<Record<string, IEvent[]>> => {
  const response = await auth()
    .axios.get('/user/vacations', {
      ...(date?.from &&
        date?.to && {
          params: {
            from: format(new Date(date.from), 'yyyy-MM-dd'),
            to: format(new Date(date.to), 'yyyy-MM-dd'),
          },
        }),
    })
    .then(({ data }: AxiosResponse<Record<string, IEvent[]>>) => data);

  return response ?? {};
};

const createVacation = async (payload: ICreateVacation): Promise<Record<string, IEvent[]>> => {
  const payloadData = {
    type: payload.type,
    startDate: payload.startDate,
    endDate: payload.endDate,
    comment: payload.comment,
    paymentType: payload.paymentType,
  };
  const response = await auth()
    .axios.post('/user/vacation', payloadData)
    .then(({ data }: AxiosResponse<Record<string, IEvent[]>>) => data);

  return response ?? {};
};

const createEvent = async (payload: IEditEvent): Promise<IEvent> => {
  const response = await auth()
    .axios.post('/admin/event', {
      name: payload.name,
      description: payload.description,
      date: payload.date,
    })
    .then(({ data }: AxiosResponse<IEvent>) => data);

  return response;
};

const removeEvent = async (payload: string): Promise<string> => {
  const response = await auth()
    .axios.delete(`/admin/event/${payload}`)
    .then(({ data }: AxiosResponse<string>) => data);

  return response;
};

const editEvent = async (payload: IEditEvent): Promise<IEvent> => {
  const response = await auth()
    .axios.put(`/admin/event/${payload.id}`, {
      name: payload.name,
      description: payload.description,
      date: payload.date,
    })
    .then(({ data }: AxiosResponse<IEvent>) => data);

  return response;
};

const loadPendingVacations = async (): Promise<IPendingVacation[]> => {
  const response = await auth()
    .axios.get(`/admin/vacations/new`)
    .then(({ data }: AxiosResponse<IPendingVacation[]>) => data);

  return response ?? [];
};

const loadVacationsDetails = async (payload: ILoadUserEvents): Promise<IVacationDetails[]> => {
  const response = await auth()
    .axios.get(`/admin/vacations/details/`, {
      params: {
        userId: payload.userId,
        from: payload.date?.from,
        to: payload.date?.to,
      },
    })
    .then(({ data }: AxiosResponse<IVacationDetails[]>) => data);

  return response ?? [];
};

const loadUserVacationsDetails = async (payload?: RangeModifier): Promise<IVacationDetails[]> => {
  const response = await auth()
    .axios.get(`/user/vacations/details/`, {
      params: {
        from: payload?.from,
        to: payload?.to,
      },
    })
    .then(({ data }: AxiosResponse<IVacationDetails[]>) => data);

  return response ?? [];
};

const handleVacation = async (vacationId: string, type: 'approve' | 'reject'): Promise<IEvent> => {
  const response = await auth()
    .axios.put(`/admin/vacations/${type}/${vacationId}`)
    .then(({ data }: AxiosResponse<IEvent>) => data);

  return response;
};

const cancelVacationRequestByUser = async (vacationId: string): Promise<IEvent> => {
  const response = await auth()
    .axios.put(`/user/vacations/reject/${vacationId}`)
    .then(({ data }: AxiosResponse<IEvent>) => data);

  return response;
};

export default {
  loadVacations,
  loadPendingVacations,
  handleVacation,
  loadUserEvents,
  createVacation,
  createEvent,
  loadUserVacation,
  loadVacationsDetails,
  loadVacationsByUser,
  loadUserVacationsDetails,
  loadNextEvents,
  removeEvent,
  editEvent,
  cancelVacationRequestByUser,
};
