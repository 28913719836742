import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import clsx from 'clsx';

import ChartComponent from './chart';
import useOverallCard from './useOverallCard';
import { IOverallCardProps } from '../types';
import useStyles from './styles';

export default function OverallCard({
  className,
  chartHeight,
  userCard,
  classes,
  overallMinutes,
  userRole,
  isDesktop,
  year,
  month,
  period,
  ...props
}: IOverallCardProps): JSX.Element {
  const innerClasses = useStyles();

  const { data, totalHours, isVisible } = useOverallCard(
    period,
    overallMinutes,
    userRole,
    year,
    month,
    isDesktop,
  );

  return (
    <Card className={clsx(innerClasses.root, className)} {...props}>
      <CardContent className={clsx(innerClasses.content)}>
        <div className={innerClasses.header}>
          <div className={innerClasses.titleBlock}>
            <div className={clsx(innerClasses.title, classes?.title)}>Overall:</div>
            <div
              className={clsx(
                innerClasses.revenueBlock,
                { [innerClasses.blured]: !isVisible && isDesktop },
                classes?.block,
              )}
            >
              {totalHours} hours
            </div>
          </div>
        </div>
        <ChartComponent
          overallHours={data}
          chartHeight={chartHeight}
          period={period}
          overallSalary={null}
          isDesktop={isDesktop}
        />
      </CardContent>
    </Card>
  );
}
