import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest } from 'redux-saga/effects';
import timeOffsService from '@/services/timeOffs.service';
import logError from '@/utils/errors';
import {
  ApproveVacationAction,
  APPROVE_VACATION,
  IEvent,
  IPendingVacation,
  LoadVacationsAction,
  LOAD_VACATIONS,
  LOAD_PENDING_VACATIONS,
  RejectVacationAction,
  REJECT_VACATION,
  resolveVacation,
  storePendingVacations,
  LOAD_USER_EVENTS,
  CreateVacationAction,
  CREATE_VACATION,
  LOAD_USER_VACATIONS,
  LOAD_ALL_EVENTS,
  LoadVacationsDetailsAction,
  LOAD_VACATIONS_DETAILS,
  IVacationDetails,
  LoadAllEventsAction,
  CreateEventAction,
  CREATE_EVENT,
  LOAD_VACATIONS_BY_USER,
  LoadVacationsByUserAction,
  LoadUserVacationsAction,
  LoadUserEventsAction,
  LOAD_USER_VACATIONS_DETAILS,
  LOAD_NEXT_EVENTS,
  REMOVE_EVENT,
  EDIT_EVENT,
  RemoveEventAction,
  EditEventAction,
  LoadUserVacationsDetailsAction,
} from '@/redux/timeOffs';
import { mergeEvents } from '@/utils';
import { IAuthUser } from '@/types/user';
import { authorizationService } from '@/services';
import { userStore } from '@/redux';
import { endOfYear, startOfYear } from 'date-fns';
import {
  storeEvents,
  storeVacations,
  setLoadingEvents,
  allEventsStore,
  storeVacationDetails,
  storeNextEvents,
  loadPendingVacations,
  loadAllEvents as loadAllEventsAction,
  loadUserVacationsDetails as loadUserVacationsDetailsAction,
} from './actions';

function* loadVacations({ payload }: LoadVacationsAction): SagaIterator {
  try {
    yield put(setLoadingEvents(true));

    const vacations: Record<string, IEvent[]> = yield call(timeOffsService.loadVacations, payload);
    yield put(storeVacations(vacations));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* loadVacationsByUser({ payload }: LoadVacationsByUserAction): SagaIterator {
  try {
    yield put(setLoadingEvents(true));

    const vacations: Record<string, IEvent[]> = yield call(
      timeOffsService.loadVacationsByUser,
      payload,
    );
    yield put(storeVacations(vacations));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* createVacation({ payload }: CreateVacationAction): SagaIterator {
  const allEventData = { date: payload.date, isAdmin: payload.isAdmin };
  try {
    yield put(setLoadingEvents(true));

    yield call(timeOffsService.createVacation, payload);
    const user: IAuthUser = yield call(authorizationService.loadUser);
    yield put(userStore(user));

    yield put(loadAllEventsAction(allEventData));
    yield put(
      loadUserVacationsDetailsAction({
        from: startOfYear(payload.date.from ?? new Date()),
        to: endOfYear(payload.date.from ?? new Date()),
      }),
    );
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* createEvent({ payload }: CreateEventAction): SagaIterator {
  const allEventData = { date: payload.monthDate, isAdmin: payload.isAdmin };

  try {
    yield put(setLoadingEvents(true));

    yield call(timeOffsService.createEvent, payload);
    yield put(loadAllEventsAction(allEventData));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* editEvent({ payload }: EditEventAction): SagaIterator {
  const allEventData = { date: payload.monthDate, isAdmin: payload.isAdmin };
  try {
    yield put(setLoadingEvents(true));

    yield call(timeOffsService.editEvent, payload);
    yield put(loadAllEventsAction(allEventData));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* removeEvent({ payload }: RemoveEventAction): SagaIterator {
  const allEventData = { date: payload.date, isAdmin: payload.isAdmin };
  try {
    yield put(setLoadingEvents(true));

    yield call(timeOffsService.removeEvent, payload.id);
    yield put(loadAllEventsAction(allEventData));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* loadUserEvents({ payload }: LoadUserEventsAction): SagaIterator {
  try {
    yield put(setLoadingEvents(true));

    const events: Record<string, IEvent[]> = yield call(timeOffsService.loadUserEvents, payload);
    yield put(storeEvents(events));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* loadAllEvents({ payload }: LoadAllEventsAction): SagaIterator {
  try {
    yield put(setLoadingEvents(true));
    const [events, vacations]: [Record<string, IEvent[]>, Record<string, IEvent[]>] = yield all([
      call(timeOffsService.loadUserEvents, payload.date),
      payload.isAdmin
        ? call(timeOffsService.loadVacations, payload.date)
        : call(timeOffsService.loadUserVacation, payload.date),
    ]);

    yield put(allEventsStore(mergeEvents(events, vacations)));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* loadNextEvents(): SagaIterator {
  try {
    yield put(setLoadingEvents(true));
    const events: IEvent[] = yield call(timeOffsService.loadNextEvents);

    yield put(storeNextEvents(events));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* loadUserVacations({ payload }: LoadUserVacationsAction): SagaIterator {
  try {
    yield put(setLoadingEvents(true));
    const vacation: Record<string, IEvent[]> = yield call(
      timeOffsService.loadUserVacation,
      payload,
    );
    yield put(storeVacations(vacation));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* loadPendingVacationsSaga(): SagaIterator {
  try {
    const pendingVacations: IPendingVacation[] = yield call(timeOffsService.loadPendingVacations);

    yield put(storePendingVacations(pendingVacations));
  } catch (e) {
    logError(e);
  }
}

function* rejectVacation({ payload }: RejectVacationAction): SagaIterator {
  const allEventData = { date: payload.date, isAdmin: payload.isAdmin };
  try {
    const vacation: IEvent = yield call(timeOffsService.handleVacation, payload.id, 'reject');
    yield put(resolveVacation(vacation));
    yield put(loadPendingVacations());
    yield put(loadAllEventsAction(allEventData));
  } catch (e) {
    logError(e);
  }
}

function* LoadVacationsDetails({ payload }: LoadVacationsDetailsAction): SagaIterator {
  yield put(setLoadingEvents(true));

  try {
    const vacantionDetails: IVacationDetails[] = yield call(
      timeOffsService.loadVacationsDetails,
      payload,
    );

    yield put(storeVacationDetails(vacantionDetails));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* LoadUserVacationsDetails({ payload }: LoadUserVacationsDetailsAction): SagaIterator {
  yield put(setLoadingEvents(true));

  try {
    const vacantionDetails: IVacationDetails[] = yield call(
      timeOffsService.loadUserVacationsDetails,
      payload,
    );

    yield put(storeVacationDetails(vacantionDetails));
  } catch (e) {
    logError(e);
  } finally {
    yield put(setLoadingEvents(false));
  }
}

function* approveVacation({ payload }: ApproveVacationAction): SagaIterator {
  const allEventData = { date: payload.date, isAdmin: payload.isAdmin };
  try {
    const vacation: IEvent = yield call(timeOffsService.handleVacation, payload.id, 'approve');
    yield put(resolveVacation(vacation));
    yield put(loadPendingVacations());
    yield put(loadAllEventsAction(allEventData));
  } catch (e) {
    logError(e);
  }
}

function* eventsRootSaga() {
  yield all([
    takeLatest(LOAD_VACATIONS, loadVacations),
    takeLatest(LOAD_PENDING_VACATIONS, loadPendingVacationsSaga),
    takeLatest(REJECT_VACATION, rejectVacation),
    takeLatest(APPROVE_VACATION, approveVacation),
    takeLatest(LOAD_USER_EVENTS, loadUserEvents),
    takeLatest(CREATE_VACATION, createVacation),
    takeLatest(CREATE_EVENT, createEvent),
    takeLatest(LOAD_USER_VACATIONS, loadUserVacations),
    takeLatest(LOAD_ALL_EVENTS, loadAllEvents),
    takeLatest(LOAD_VACATIONS_DETAILS, LoadVacationsDetails),
    takeLatest(LOAD_USER_VACATIONS_DETAILS, LoadUserVacationsDetails),
    takeLatest(LOAD_VACATIONS_BY_USER, loadVacationsByUser),
    takeLatest(LOAD_NEXT_EVENTS, loadNextEvents),
    takeLatest(REMOVE_EVENT, removeEvent),
    takeLatest(EDIT_EVENT, editEvent),
  ]);
}

export default eventsRootSaga;
