import React, { useCallback } from 'react';
import { Grid, TableCell, Typography, ButtonBase, Badge } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { differenceInBusinessDays, differenceInCalendarDays, format } from 'date-fns';
import clsx from 'clsx';
import Button from '@/components/Buttons/Button';
import { EditPenIcon } from '@/components/Icons';
import { ICellProps } from './types';
import useStyles from './styles';

const Cell: React.FC<ICellProps> = ({
  date,
  disabled,
  className,
  onClick,
  children,
  contentType,
  openReportModal,
  isDesktop,
  hasRecords,
  hasVacation,
  ...props
}) => {
  const classes = useStyles();
  const currentDate = new Date();
  const businessDiff = differenceInBusinessDays(date, currentDate);
  const calendarDiff = differenceInCalendarDays(date, currentDate);
  const isEditable =
    calendarDiff <= 0 && businessDiff > -3 && date.getMonth() === currentDate.getMonth();
  const isToday = calendarDiff === 0;
  const isFuture = calendarDiff > 0;

  const clickHandler = useCallback(() => {
    onClick?.();
  }, [onClick]);

  return (
    <TableCell
      component="td"
      scope="row"
      className={clsx(classes.cell, className, {
        [classes.disabledCell]: disabled,
        [classes.notClickable]: disabled, // || isFuture,
      })}
      {...props}
    >
      <ButtonBase
        onClick={clickHandler}
        disabled={disabled || (contentType === 'reports' && (isFuture || !isEditable))}
        className={classes.cellButton}
      >
        <Grid container className={classes.content}>
          <Grid item xs={12}>
            <Grid container className={classes.cellHeader}>
              <Badge
                badgeContent={
                  <div
                    className={classes.contentBadge}
                    hidden={isDesktop || contentType === 'reports' || !hasRecords}
                  />
                }
              >
                <Grid container item xs={isToday ? 12 : 4} justifyContent="flex-start">
                  {isToday ? (
                    <div className={classes.todayShape}>
                      <Typography>{format(date, 'dd')}</Typography>
                    </div>
                  ) : (
                    <Typography>{format(date, 'dd')}</Typography>
                  )}
                </Grid>
              </Badge>
            </Grid>
            <Grid container className={classes.cellBody}>
              <Grid item xs={12}>
                {children}
                {isEditable && isDesktop && contentType === 'reports' && hasRecords && (
                  <Button className={classes.editIcon} onClick={() => openReportModal?.(date)}>
                    <EditPenIcon fill="transparent" fontSize="inherit" />
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ButtonBase>
      {isEditable && isDesktop && contentType === 'reports' && (
        <Button className={classes.addIcon} onClick={() => openReportModal?.(date)}>
          <Add />
        </Button>
      )}
    </TableCell>
  );
};

export default Cell;
