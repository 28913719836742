import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      backgroundColor: theme.palette.type === 'light' ? 'rgba(84, 53, 184, 0.3)' : '#322D59',
      height: 80,
      borderRadius: '7px',
      paddingTop: '15px',
      paddingBottom: '15px',
      paddingLeft: '21px',
      paddingRight: '13px',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      marginBottom: '20px',
      '& .MuiTypography-body1': {
        fontWeight: 500,
      },
    },
    container: {
      display: 'flex',
      padding: '0px 8px',
      width: '100%',
      justifyContent: 'space-between',
      flexDirection: 'column',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    block: {
      backgroundColor: '#5535B8',
      minHeight: '77px',
      padding: theme.spacing(1),
      width: '100%',
      margin: '8px',
      borderRadius: '6px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    text: {
      color: theme.palette.text.primary,
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '20px',
      [theme.breakpoints.up('md')]: {
        fontSize: '16px',
        lineHeight: '25px',
        fontWeight: '600 !important',
      },
    },
    mobileText: {
      color: theme.palette.common.white,
    },
    title: {
      fontWeight: 400,
      marginRight: '10px',
    },
    divider: {
      margin: '0 30px',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '1px',
    },
    userBlock: {
      display: 'flex',
      alignItems: 'center',
    },
  }),
);

export default useStyles;
