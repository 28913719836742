import { useEffect, useState } from 'react';
import {
  Card,
  Button,
  TextField,
  InputAdornment,
  Avatar,
  Typography,
  ButtonBase,
} from '@material-ui/core';
import { BackArrow } from '@/components/Icons';
import { Link, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { clearUsers, usersPaginationSelector, usersSelector } from '@/redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import clsx from 'clsx';
import useFetchUsers from '@/hooks/useFetchUsers';
import { userRoles } from '@/constants';
import { LayersIcon, SearchIcon } from '../Icons';

import useStyles from './styles';
import { ISidebarUsersProps } from './types';

const buttons = [
  { name: 'All', value: '' },
  { name: 'Active', value: 'Active' },
  { name: 'Inactive', value: 'Inactive' },
];
const userRolesToFetch = [userRoles.user, userRoles.trainee];

export default function SidebarUsers({ linkTo, isTimeOff }: ISidebarUsersProps) {
  const classes = useStyles();

  const router = useRouteMatch<{ id: string }>();

  const dispatch = useDispatch();

  const users = useSelector(usersSelector);

  const usersPagination = useSelector(usersPaginationSelector);

  const isUserPresent = !!router.params.id;
  const [selectTab, setSelectTab] = useState(buttons[1]);

  const [searchName, setSearchName] = useState('');

  const indexActiveBtn = buttons.indexOf(selectTab);
  const widthIndicator = 64;

  const fetchUsers = useFetchUsers(searchName, {
    ...(selectTab.value !== '' && { status: selectTab.value }),
    roles: userRolesToFetch,
    limit: 100,
  });

  const resetUsers = () => {
    setSearchName('');
    dispatch(clearUsers());
    fetchUsers();
  };

  useEffect(() => {
    if (isUserPresent) {
      setSelectTab(buttons[1]);
    }
  }, [isUserPresent]);

  return (
    <Card className={classes.card}>
      {isUserPresent ? (
        <div className={classes.btnWrap}>
          <Link onClick={resetUsers} className={classes.link} to={`/${linkTo}`}>
            <ButtonBase disableRipple>
              <BackArrow className={classes.arrow} />
              <Typography variant="h5" component="span">
                Back to all
              </Typography>
            </ButtonBase>
          </Link>
        </div>
      ) : (
        <div className={classes.btnWrap}>
          {buttons.map((button) => (
            <Button
              key={button.name}
              color={selectTab === button ? 'primary' : 'default'}
              className={classes.btn}
              onClick={() => setSelectTab(button)}
            >
              {button.name === 'All' ? <LayersIcon className={classes.buttonIcon} /> : null}
              {button.name}
            </Button>
          ))}
          <div className={classes.indicator} style={{ left: indexActiveBtn * widthIndicator }} />
        </div>
      )}

      <TextField
        value={searchName}
        onChange={(e) => setSearchName(e.target.value)}
        autoComplete="off"
        variant="outlined"
        name="search"
        className={classes.search}
        placeholder="Type anything"
        type="search"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          ),
        }}
      />
      <InfiniteScroll
        dataLength={users.length}
        next={fetchUsers}
        hasMore={usersPagination.hasNext}
        loader={null}
      >
        {users.map((user) => (
          <Link
            onClick={resetUsers}
            key={user.id}
            className={classes.link}
            to={`/${linkTo}/${user.id}`}
          >
            <ButtonBase disableRipple className={classes.user} key={user.id}>
              <Avatar
                alt={user.fullName}
                src={user.photo || '/src/assets/svg/logo.svg'}
                className={classes.avatar}
              />
              <Typography
                variant="body1"
                component="p"
                className={clsx({ [classes.selectedUser]: router.params.id === user.id })}
              >
                {user.fullName}
                {isTimeOff && (
                  <>
                    &nbsp; ({Math.floor(user?.vacationsDetail.totalLeft ?? 0)} /
                    {Math.floor(user?.vacationsDetail.total ?? 0)})
                  </>
                )}
              </Typography>
            </ButtonBase>
          </Link>
        ))}
      </InfiniteScroll>
    </Card>
  );
}
