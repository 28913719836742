import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appRoot: (props: { content?: number }) => ({
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      '& .MuiTab-root': {
        minWidth: `${100 / (props?.content ?? 1)}%`,
        width: `${100 / (props?.content ?? 1)}%`,
        maxWidth: '100%',
        borderTop: 'none',
        minHeight: '51px',
        height: '51px',
        fontSize: '18px',
        textTransform: 'none',

        '&:last-child': {
          borderRight: 'none',
        },
        '&:first-child': {
          borderLeft: 'none',
        },
      },
      '& .MuiTabs-root': {
        width: '100%',
        [theme.breakpoints.up('lg')]: {
          marginLeft: '27px',
        },
      },
      '& .MuiAppBar-root': {
        backgroundColor: 'transparent',
        boxShadow: 'none',
        width: 'calc(100% + 53px)',
        marginTop: '-24px',
        marginLeft: '-26px',
      },
      '& .MuiTabs-flexContainer': {
        justifyContent: 'space-around',
      },
      '& .MuiTabScrollButton-root': {
        color: theme.palette.text.primary,
      },
      '& .Mui-selected': {
        background: '#5435B8',
        color: theme.palette.white,
        borderBottom: '1px solid white',
      },
      [theme.breakpoints.up('lg')]: {
        alignItems: 'center',
      },
    }),
    appBar: {
      display: 'flex',
      [theme.breakpoints.up('lg')]: {
        alignItems: 'center',
        padding: theme.spacing(0),
      },
    },
    indicator: {
      display: 'none',
    },
    tooltipArrow: {
      color: theme.palette.white,
      backgroundColor: '#000',
    },
    label: {
      color: theme.palette.text.primary,
      '&.MuiInputLabel-marginDense': {
        marginTop: '-30px',
        marginLeft: '-10px',
      },
      borderBottom: '1px solid white',
    },
    arrow: {
      color: '#000',
    },
  }),
);

export default useStyles;
