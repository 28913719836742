import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    calendar: {
      boxShadow: 'none',
    },
    content: {
      display: 'flex',
      alignItems: 'flex-start',
      width: '100%',
      flexDirection: 'column',
      '& .MuiCard-root': {
        width: '100%',
      },
    },
    button: {
      padding: '12px 27px',
      textTransform: 'capitalize',
      lineHeight: '19px',
      marginBottom: '-19px',
      marginLeft: '21px',
      maxHeight: '39px',
    },
  }),
);

export default useStyles;
