import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { BirthDayIcon, PartyIcon } from '@/components/Icons';
import clsx from 'clsx';
import { format } from 'date-fns';
import { IEventCardProps } from './types';
import useStyles from './styles';

const BirthDayCard = ({
  eventDate,
  userFullName,
  isBirthDay = false,
  eventName,
  className,
}: IEventCardProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <div className={classes.item}>
          <Typography className={clsx(classes.description, classes.title)} gutterBottom>
            {` ${format(new Date(eventDate ?? ''), 'iiii, MMMM do, ')} ${
              isBirthDay ? `${userFullName}` : eventName
            } `}
          </Typography>
          {isBirthDay ? (
            <Typography className={classes.description} gutterBottom>
              Wish him(her) a happy birthday
            </Typography>
          ) : null}
        </div>
      </CardContent>
      {isBirthDay ? (
        <BirthDayIcon className={clsx(classes.cardIcon, classes.birthdayIcon)} />
      ) : (
        <PartyIcon className={clsx(classes.cardIcon, classes.partyIcon)} />
      )}
    </Card>
  );
};

export default BirthDayCard;
