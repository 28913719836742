import Grid from '@material-ui/core/Grid';
import { RouteComponentProps } from 'react-router-dom';
import {
  OverallCard,
  RevenueCard,
  TaxesCard,
  CombinedStatisticsCard,
  UpcomingEventsCard,
} from '@/components/Cards';
import useDateChanger from '@/hooks/useDateChanger';
import React from 'react';
import useDashboard from './useDashboard';
import useStyles from './styles';

const Home: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const { month, prevMonth, nextMonth, year, nextYear, prevYear, period, changePeriod } =
    useDateChanger();
  const { user, overallMinutes, overallSalary, isDesktop } = useDashboard(month);
  return (
    <Grid container spacing={isDesktop ? 2 : 0}>
      <Grid item xs={12}>
        <Grid container spacing={isDesktop ? 2 : 0}>
          <Grid item xs={12} md={4}>
            <Grid container spacing={isDesktop ? 2 : 0}>
              <Grid item xs={12}>
                {user && <CombinedStatisticsCard user={user} />}
              </Grid>
              <Grid item xs={12}>
                {user && <TaxesCard />}
              </Grid>
              <Grid item xs={12}>
                <UpcomingEventsCard />
              </Grid>
            </Grid>
          </Grid>
          <Grid className={classes.contentBlock} item xs={12} md={8}>
            <RevenueCard
              isDesktop={isDesktop}
              overallSalary={overallSalary}
              userRole={user?.role}
              className={classes.revenueCard}
              classes={{ title: classes.cardTitle }}
              userCard
              chartHeight={263}
              month={month}
              prevMonth={prevMonth}
              nextMonth={nextMonth}
              year={year}
              nextYear={nextYear}
              prevYear={prevYear}
              period={period}
              changePeriod={changePeriod}
            />
            <OverallCard
              isDesktop={isDesktop}
              classes={{ title: classes.cardTitle, block: classes.cardBlock }}
              overallMinutes={overallMinutes}
              userRole={user?.role}
              userCard
              chartHeight={259}
              year={year}
              period={period}
              month={month}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
