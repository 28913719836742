import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() =>
  createStyles({
    switchTitle: {
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    toggleBtn: {
      textTransform: 'capitalize',
      fontSize: 12,
    },
  }),
);

export default useStyles;
