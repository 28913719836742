import { Modal, Card, CardContent } from '@material-ui/core';
import { CustomTabs } from '@/components/FormItems';
import ReportForm from '@/components/Forms/ReportForm';
import { maxDisplayedFullWidthTabs } from '@/constants';
import useStyles from '../styles';
import { ICreateReportModalProps } from '../types';

const ReportModal = ({
  open,
  closeModalHandler,
  setReport,
  reports,
  onSubmitHandler,
  user,
  onSuccessDelete,
}: ICreateReportModalProps): JSX.Element => {
  const classes = useStyles();

  const closeModal = () => {
    closeModalHandler(false);
    setReport(undefined);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className={classes.modalWrap}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={classes.smallModalCard}>
        <CardContent className={classes.smallModalCardContent}>
          <div>
            {reports ? (
              <CustomTabs
                labels={reports.map((el, idx) =>
                  reports.length < maxDisplayedFullWidthTabs
                    ? `Edit the Report ${idx + 1}`
                    : `${idx + 1}`,
                )}
                content={reports.map((report) => {
                  return (
                    <ReportForm
                      setReport={setReport}
                      onSubmitHandler={onSubmitHandler}
                      report={report}
                      handleClose={closeModal}
                      user={user}
                      onSuccessDelete={onSuccessDelete}
                    />
                  );
                })}
              />
            ) : (
              <ReportForm
                handleClose={closeModal}
                user={user}
                onSubmitHandler={onSubmitHandler}
                onSuccessDelete={onSuccessDelete}
              />
            )}
          </div>
        </CardContent>
      </Card>
    </Modal>
  );
};

export default ReportModal;
