import {
  FormGroup,
  Grid,
  Button,
  Typography,
  TextField,
  InputLabel,
  Box,
  MenuItem,
  Divider,
} from '@material-ui/core';
import { CustomInput, DatePicker } from '@/components/FormItems';
import { vacationTypeOptions, vacationPaymentTypeOptions, VacationType } from '@/types/vacation';
import VacationRangeDatePicker from '@/components/VacationRangeDatePicker';
import clsx from 'clsx';
import pluralize from 'pluralize';
import { format, isValid } from 'date-fns';
import useVacationForm from './useVacationForm';
import useStyles from './styles';
import { IVacationFormProps } from './types';

const VacationForm = ({
  onSubmitHandler,
  closeModal,
  user,
  date,
  setDate,
}: IVacationFormProps): JSX.Element => {
  const classes = useStyles();

  const {
    total,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    isDisabled,
    isSubmitting,
  } = useVacationForm(onSubmitHandler, date, user);

  return (
    <div className={classes.root}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormGroup>
              <CustomInput
                select
                variant="outlined"
                id="type"
                name="type"
                label="Leave Types"
                disabled={isSubmitting}
                onChange={(e) => {
                  setDate([null, null]);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                className={classes.reportInput}
                value={values?.type ?? ''}
                SelectProps={{
                  MenuProps: {
                    placeholder: 'Leave Types',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    anchorReference: 'anchorEl',
                    getContentAnchorEl: null,
                    transformOrigin: {
                      horizontal: 'center',
                      vertical: 'top',
                    },
                    classes: { paper: classes.menu },
                  },
                }}
              >
                {vacationTypeOptions.map((type) => (
                  <MenuItem value={type.value} key={type.label}>
                    {type.label}
                  </MenuItem>
                ))}
              </CustomInput>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <CustomInput
                select
                variant="outlined"
                id="paymentType"
                name="paymentType"
                label="Payment Type"
                onChange={handleChange}
                disabled={isSubmitting}
                onBlur={handleBlur}
                className={classes.reportInput}
                value={values?.paymentType ?? ''}
                SelectProps={{
                  MenuProps: {
                    placeholder: 'Payment Type',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    anchorReference: 'anchorEl',
                    getContentAnchorEl: null,
                    transformOrigin: {
                      horizontal: 'center',
                      vertical: 'top',
                    },
                    classes: { paper: classes.menu },
                  },
                }}
              >
                {vacationPaymentTypeOptions.map((type) => (
                  <MenuItem value={type.value} key={type.label}>
                    {type.label}
                  </MenuItem>
                ))}
              </CustomInput>
            </FormGroup>
          </Grid>
          {values.type === VacationType.DayOff ? (
            <Grid item xs={12}>
              <DatePicker
                disableToolbar
                disabled={isSubmitting}
                id="startDate"
                margin="normal"
                variant="inline"
                format="dd.MM.yyyy"
                label="Choose Date:"
                onBlur={handleBlur}
                className={classes.dateInput}
                onChange={(v) => setDate([v, v])}
                error={touched.startDate && !!errors.startDate}
                helperText={touched.startDate && errors?.startDate ? errors?.startDate : ''}
                value={date[0]}
              />
            </Grid>
          ) : (
            <Grid item xs={12}>
              <InputLabel className={classes.label} error={!!errors?.type}>
                Choose Duration:
              </InputLabel>
              <VacationRangeDatePicker
                date={date}
                setDate={setDate}
                disabled={isSubmitting}
                startError={touched.startDate && !!errors.startDate ? errors.startDate : ''}
                endError={touched.endDate && !!errors.endDate ? errors.endDate : ''}
                startOnBlur={handleBlur}
                endOnBlur={handleBlur}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <TextField
              className={classes.descriptionInput}
              multiline
              fullWidth
              name="comment"
              label="Comment"
              id="comment"
              disabled={isSubmitting}
              variant="outlined"
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.comment ?? ''}
              maxRows={4}
              rows={4}
              error={touched.comment && !!errors.comment}
              helperText={touched.comment && errors?.comment ? errors?.comment : ' '}
            />
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box className={classes.totalInfo}>
                <Typography>Total:</Typography>
                {date?.[0] && date?.[1] && isValid(date?.[0]) && isValid(date?.[1]) && (
                  <Typography>
                    {total} {pluralize('day', total)} of{' '}
                    {vacationPaymentTypeOptions
                      .find((item) => item.value === values.paymentType)
                      ?.label?.toLowerCase()}{' '}
                    {values.type?.toLowerCase()} ({format(date[0], 'dd.MM.yyyy')} -{' '}
                    {format(date[1], 'dd.MM.yyyy')})
                  </Typography>
                )}
                <Divider className={classes.divider} />
              </Box>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  type="submit"
                  fullWidth
                  classes={{
                    root: clsx(classes.button, classes.submitButton),
                    label: classes.buttonLabel,
                  }}
                  disabled={isDisabled}
                >
                  Submit
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  disabled={isSubmitting}
                  variant="contained"
                  onClick={closeModal}
                  fullWidth
                  classes={{
                    root: classes.button,
                    label: classes.buttonLabel,
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default VacationForm;
