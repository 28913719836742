import { ContractStatusEnum } from '@/types/contract';
import { DateRange } from '@mui/lab';
import { addMonths, addYears, endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';

const date = new Date();

export const defaultUserValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  hireDate: new Date().toString(),
  role: '',
  status: '',
  timeType: '',
  rate: '',
  overTimePercent: 10,
  password: '',
  userId: null,
  vacationsDetail: {
    firstHalfYearLeft: 0,
    firstHalfYearTotal: 0,
    secondHalfYearLeft: 0,
    secondHalfYearTotal: 0,
    totalLeft: 0,
    total: 0,
  },
  sickLeavesDetail: {
    paidLeft: 0,
    paidTotal: 0,
    unpaidLeft: 0,
    unpaidTotal: 0,
    totalLeft: 0,
    total: 0,
  },
};

export const defaultProjectValues = {
  projectName: '',
  status: 'Open',
  description: 'Project',
  id: '',
};

export const defaultContractValues = {
  contractName: '',
  startAt: new Date().toISOString(),
  endAt: addMonths(new Date(), 6).toISOString(), // default contract length
  status: ContractStatusEnum.Active,
  id: '',
};

export const defaultEditUserRateValues = {
  rate: 0,
  changeDate: date,
  status: 'Active',
};

export const getYtdAndNextYearDateRangePickerState = (): DateRange<Date | null> => {
  const end = addYears(endOfYear(new Date()), 1);
  const start = startOfYear(new Date());
  return [start, end];
};

export const getCurrentMonthRangeDatePickerState = (): DateRange<Date | null> => {
  const start = startOfMonth(new Date());
  const end = endOfMonth(new Date());

  start.setHours(12);
  end.setHours(12);

  return [start, end];
};
