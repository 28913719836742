import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    navBlock: {
      display: 'flex',
      alignItems: 'center',
    },
    btn: {
      backgroundColor: 'white',
      color: '#5435B8',
      fontSize: '12px',
      '&:hover': {
        backgroundColor: '#5435B8',
        color: 'white',
      },
    },
    select: {
      '&:after, &:before': {
        display: 'none',
      },
    },
    activeBtn: {
      backgroundColor: '#5435B8',
      fontSize: '9px',
      lineHeight: '12.5px',
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        color: `${theme.palette.type === 'light' ? theme.palette.primary.main : 'white'}`,
      },
    },
    userActiveBtn: {
      backgroundColor: '#5435B8',
      fontSize: '9px',
      lineHeight: '12.5px',
      color: 'white',
      '&:hover': {
        backgroundColor: theme.palette.background.paper,
        color: `${theme.palette.type === 'light' ? theme.palette.primary.main : 'white'}`,
      },
    },
    btnGroup: {
      height: '17px',
      borderRadius: '10px',
      '& > *': {
        textTransform: 'unset',
      },
    },
    userBtnGroup: {
      height: '23px',
      borderRadius: '10px',
      '& > *': {
        textTransform: 'unset',
      },
    },
  }),
);

export default useStyles;
