import React, { useCallback, useMemo } from 'react';
import { LaunchOutlined } from '@material-ui/icons';
import { useLocation, useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Box, Link } from '@material-ui/core';
import clsx from 'clsx';
import { Logo } from '@/components/Icons';
import defaultRoutes from '@/router/routes';
import { saveSidebar } from '@/redux/settings/actions';
import { capitalize } from '@/constants';
import { sidebarSelector } from '@/redux/settings/selectors';
import { userRoleSelector } from '@/redux/authorization/selectors';
import useStyles from './styles';

const sidebarRoutes = defaultRoutes.filter((route) => route.isSidebar);

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const sidebar = useSelector(sidebarSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const onCloseHandler = useCallback(() => {
    dispatch(saveSidebar(false));
  }, [dispatch]);

  const onClickHandler = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  const userRole = useSelector(userRoleSelector);
  const routes = useMemo(() => {
    if (!userRole) {
      return [];
    }

    return sidebarRoutes.filter((r) => r.permissions.some((p) => p === userRole));
  }, [userRole]);

  return (
    <div>
      <Drawer
        variant="permanent"
        anchor="left"
        open={sidebar}
        onClose={onCloseHandler}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: sidebar,
          [classes.drawerClose]: !sidebar,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: sidebar,
            [classes.drawerClose]: !sidebar,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <Logo
            showCaption={sidebar}
            className={clsx(classes.logo, { [classes.logoOpen]: sidebar })}
          />
        </div>
        <Box display="flex" flexGrow={1} flexDirection="column">
          <List>
            {routes.map(({ name, path, icon: Icon }) => (
              <ListItem
                button
                key={name}
                onClick={() => onClickHandler(path)}
                selected={location.pathname === path}
                className={classes.listItem}
              >
                {Icon && (
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                )}
                <ListItemText primary={capitalize(name)} />
              </ListItem>
            ))}
          </List>
        </Box>

        <Link
          underline="none"
          target="_blank"
          color="inherit"
          href="https://www.notion.so/b1214195c32e4f64b3451a9dd598b2b0?v=8be776cfce864af8bbf5d91121f5dd32&pvs=4"
        >
          <ListItem button selected className={classes.listItem}>
            <ListItemIcon>
              <LaunchOutlined />
            </ListItemIcon>
            <ListItemText primary="Wiki Devima" />
          </ListItem>
        </Link>
      </Drawer>
    </div>
  );
};

export default Sidebar;
