import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    spacing: {
      margin: theme.spacing(2, 0),
    },
    earningsCardRoot: {
      boxShadow: '3px 0px 15px rgba(78, 81, 97, 0.14)',
      minWidth: '286px',
      height: 'inherit',
      paddingBottom: '10px',
      borderRadius: 0,
      [theme.breakpoints.up('md')]: {
        background: theme.palette.background.paper,
        borderRadius: 7,
      },
    },
    contentBlock: {
      background: '#CFC6EB',
      [theme.breakpoints.up('md')]: {
        background: 'none',
      },
    },
    earningsCardItem: {
      marginLeft: '26px',
      [theme.breakpoints.up('lg')]: {
        margin: theme.spacing(3.125, 0),
        marginLeft: '26px',
      },
    },
    cardTitle: {
      fontSize: 18,
      fontWeight: 500,
      lineHeight: '25px',
    },
    cardBlock: {
      marginLeft: '12px',
      display: 'flex',
      fontSize: 16,
      lineHeight: '15px',
    },
    revenueCard: {
      marginBottom: 20,
    },
  }),
);

export default useStyles;
