import {
  endOfDay,
  isSameDay,
  isWeekend,
  isWithinInterval,
  startOfDay,
  startOfYear,
} from 'date-fns';

export function formatMinutesToHoursPeriod(minutes: number) {
  const hrs = Math.floor(minutes / 60);
  let mins = (minutes % 60).toString();

  if (mins.length === 1) {
    mins += '0';
  }

  return `${hrs}:${mins}`;
}

const easter = (y: number): Date => {
  const date = new Date();
  date.setHours(0, 0, 0, 0);
  date.setFullYear(y);

  const d = ((y % 19) * 19 + 15) % 30;
  const e = ((y % 4) * 2 + (y % 7) * 4 + d * 6 + 6) % 7;
  const f = d + e;

  if (f <= 26) {
    date.setMonth(3, f + 4);
  } else if (f > 26) {
    date.setMonth(4, f - 26);
  }

  return date;
};

export const getHolidays = (year: number) => [
  {
    id: '0',
    name: 'New Year',
    date: new Date(`${year}-01-01`),
  }, // New Year
  {
    id: '2',
    name: 'Easter',
    date: easter(year),
  }, // Easter
  {
    id: '3',
    name: 'Independence Day of Ukraine',
    date: new Date(year, 7, 24),
  }, // Independence Day of Ukraine
  {
    id: '3',
    name: 'Christmas',
    date: new Date(year, 11, 25),
  }, // Christmas
];

export const isWorkDay = (date: Date) => {
  const holidays = getHolidays(date.getFullYear());
  const isHoliday = holidays.some((h) => isSameDay(h.date, date));
  return !isWeekend(date) && !isHoliday;
};

export const getYearLefts = (dates: Date[]) => {
  return dates.reduce(
    (acc, item) => {
      if (
        isWithinInterval(startOfDay(item), {
          start: startOfYear(new Date()),
          end: endOfDay(new Date(new Date().getFullYear(), 5, 30)),
        })
      ) {
        acc.first += 1;
      } else {
        acc.second += 1;
      }

      return acc;
    },
    { first: 0, second: 0 },
  );
};
