import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    title: {
      fontWeight: 600,
      fontSize: 18,
      lineHeight: '38px',
    },
    text: {
      fontSize: 16,
      lineHeight: '30px',
      textWrap: 'wrap',
    },
    textMute: {
      color: '#8C8D98',
    },
    sup: {
      fontSize: 18,
      lineHeight: '30px',
    },
    box: {
      display: 'flex',
      alignItems: 'center',
      fontSize: 28,
      justifyContent: 'space-between',
    },
    divider: {
      marginTop: '20px',
      marginBottom: '15px',
      backgroundColor: theme.palette.text.primary,
    },
    blured: {
      [theme.breakpoints.up('md')]: {
        filter: `blur(10px)`,
      },
    },
    boxWrap: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    progressBar: {
      marginLeft: '10px',
      flexShrink: 0,
      display: 'flex',
    },
  }),
);

export default useStyles;
