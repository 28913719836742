import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikHelpers, useFormik } from 'formik';
import { userProjectsSelector, loadUserProjects, eventsLoadingSelector } from '@/redux';
import { VacationPaymentType, VacationType } from '@/types/vacation';
import { IVacationData } from '@/interfaces/app/entities/vacation.interface';
import { vacationSchema } from '@/validations';
import { DateRange } from '@mui/lab/DateRangePicker/RangeTypes';
import { isValid as isDataValid, eachDayOfInterval } from 'date-fns';
import { IAuthUser } from '@/types/user';
import { isWorkDay } from '@/utils';

const useVacationForm = (
  onSubmitHandler: (data: IVacationData, resetForm: FormikHelpers<IVacationData>) => void,
  date: DateRange<Date | null>,
  user: IAuthUser | null,
) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUserProjects());
  }, [dispatch]);

  const projects = useSelector(userProjectsSelector);
  const eventsLoading = useSelector(eventsLoadingSelector);
  const total = useMemo(
    () =>
      date?.[0] && isDataValid(date[0]) && date?.[1] && isDataValid(date[1])
        ? eachDayOfInterval({ start: date[0], end: date[1] }).filter(isWorkDay).length
        : 0,

    [date],
  );

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
    isSubmitting,
    setValues,
    setTouched,
  } = useFormik<IVacationData>({
    validationSchema: vacationSchema(user),
    onSubmit: onSubmitHandler,
    initialValues: {
      type: VacationType.DayOff,
      startDate: '',
      endDate: '',
      comment: '',
      paymentType: VacationPaymentType.Paid,
    },
  });

  const isDisabled = useMemo(() => eventsLoading || isSubmitting, [eventsLoading, isSubmitting]);

  useEffect(() => {
    setValues((prev) => ({ ...prev, startDate: date[0], endDate: date[1] }));
  }, [date, setFieldValue, setValues]);

  return {
    total,
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    projects,
    setFieldValue,
    setTouched,
    isDisabled,
    isSubmitting,
  };
};

export default useVacationForm;
