import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      '&.MuiCardContent-root': {
        background: 'transparent',
      },
      padding: '0',
      '&:last-child': {
        paddingBottom: 0,
      },
    },
    linkBox: {
      color: theme.palette.common.black,
      display: 'flex',
      justifyContent: 'flex-end',
      fontSize: theme.typography.fontSize,
      '&>a': {
        padding: theme.spacing(0.5),
      },
    },
  }),
);

export default useStyles;
