import SvgIcon from '@material-ui/core/SvgIcon';
import useStyles from '@/components/Icons/styles';
import { SvgIconType } from '../types';

export default function BirthDayIcon({ viewBox = '0, 0, 204, 179', ...rest }: SvgIconType) {
  const classes = useStyles();

  return (
    <SvgIcon classes={classes} viewBox={viewBox} {...rest}>
      <g clipPath="url(#clip0_6790_1170)">
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M30.9963 31.8843H30.1868V36.5299H30.9963V31.8843Z"
            fill="#61D8A9"
          />
          <path opacity="0.5" d="M32.885 34.6172V33.7974H28.2979V34.6172H32.885Z" fill="#61D8A9" />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M59.7707 132.989H58.9612V137.635H59.7707V132.989Z"
            fill="#61D8A9"
          />
          <path opacity="0.5" d="M61.657 135.722V134.902H57.0699V135.722H61.657Z" fill="#61D8A9" />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M19.4936 79.0269H18.6841V83.6725H19.4936V79.0269Z"
            fill="#61D8A9"
          />
          <path
            opacity="0.5"
            d="M21.3823 81.7598V80.9399H16.7952V81.7598H21.3823Z"
            fill="#61D8A9"
          />
        </g>
        <g opacity="0.5">
          <path opacity="0.5" d="M161.214 0H160.405V4.64561H161.214V0Z" fill="#61D8A9" />
          <path
            opacity="0.5"
            d="M163.103 2.7329V1.91309L158.516 1.91309V2.7329L163.103 2.7329Z"
            fill="#61D8A9"
          />
        </g>
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M24.6252 11.5537H23.8157V16.1993H24.6252V11.5537Z"
            fill="#61D8A9"
          />
          <path
            opacity="0.5"
            d="M26.5115 14.2866V13.4668H21.9244V14.2866H26.5115Z"
            fill="#61D8A9"
          />
        </g>
        <path
          opacity="0.5"
          d="M40.134 116.411C39.911 116.312 39.7316 116.134 39.6299 115.911C39.5281 115.687 39.5112 115.433 39.5821 115.197C39.5936 115.161 39.601 115.124 39.604 115.086C39.6204 114.975 39.5983 114.861 39.5415 114.764C39.4847 114.666 39.3966 114.592 39.292 114.553C39.1875 114.513 39.0727 114.512 38.9671 114.548C38.8615 114.584 38.7714 114.656 38.7119 114.752C38.6896 114.782 38.6708 114.815 38.656 114.85C38.5587 115.076 38.3828 115.258 38.1618 115.361C37.9409 115.464 37.6902 115.481 37.4575 115.409C37.422 115.397 37.3853 115.39 37.3482 115.387C37.2378 115.37 37.1253 115.393 37.0293 115.45C36.9333 115.508 36.8598 115.597 36.8211 115.703C36.7823 115.809 36.7807 115.925 36.8165 116.032C36.8522 116.139 36.9232 116.23 37.0176 116.29C37.0477 116.313 37.0803 116.332 37.1148 116.347C37.3377 116.446 37.5171 116.624 37.6189 116.847C37.7206 117.071 37.7376 117.325 37.6666 117.561C37.6551 117.597 37.6478 117.634 37.6447 117.671C37.6284 117.783 37.6505 117.897 37.7073 117.994C37.7641 118.092 37.8522 118.166 37.9567 118.205C38.0613 118.245 38.176 118.246 38.2816 118.21C38.3873 118.174 38.4774 118.102 38.5369 118.006C38.5592 117.976 38.578 117.943 38.5928 117.908C38.6901 117.682 38.866 117.5 39.0869 117.397C39.3079 117.294 39.5585 117.277 39.7912 117.349C39.8267 117.361 39.8634 117.368 39.9006 117.371C40.0109 117.388 40.1235 117.365 40.2194 117.308C40.3154 117.25 40.3889 117.161 40.4277 117.055C40.4664 116.949 40.4681 116.833 40.4323 116.726C40.3965 116.619 40.3255 116.528 40.2312 116.468C40.201 116.445 40.1684 116.426 40.134 116.411Z"
          fill="#6C63FF"
        />
        <path
          opacity="0.5"
          d="M36.123 160.565C35.9 160.467 35.7206 160.289 35.6189 160.065C35.5172 159.841 35.5002 159.587 35.5712 159.352C35.5826 159.316 35.59 159.278 35.593 159.241C35.6094 159.129 35.5873 159.015 35.5305 158.918C35.4737 158.821 35.3856 158.746 35.281 158.707C35.1765 158.668 35.0618 158.666 34.9562 158.702C34.8505 158.739 34.7604 158.81 34.7009 158.906C34.6786 158.936 34.6598 158.97 34.645 159.004C34.5482 159.23 34.3729 159.412 34.1524 159.515C33.9319 159.619 33.6816 159.637 33.449 159.566C33.4135 159.554 33.3768 159.547 33.3396 159.544C33.2293 159.527 33.1167 159.549 33.0208 159.607C32.9248 159.664 32.8513 159.754 32.8125 159.86C32.7738 159.965 32.7721 160.082 32.8079 160.189C32.8437 160.296 32.9147 160.387 33.009 160.447C33.0392 160.47 33.0718 160.489 33.1062 160.504C33.3292 160.602 33.5086 160.78 33.6103 161.004C33.7121 161.228 33.7291 161.482 33.6581 161.717C33.6466 161.753 33.6393 161.791 33.6362 161.828C33.6198 161.94 33.6419 162.054 33.6987 162.151C33.7555 162.248 33.8436 162.323 33.9482 162.362C34.0527 162.401 34.1675 162.403 34.2731 162.367C34.3787 162.33 34.4688 162.259 34.5283 162.163C34.5506 162.133 34.5694 162.099 34.5842 162.065C34.6816 161.839 34.8574 161.657 35.0784 161.554C35.2993 161.451 35.55 161.434 35.7827 161.506C35.8182 161.517 35.8549 161.525 35.892 161.528C36.0024 161.544 36.1149 161.522 36.2109 161.465C36.3069 161.407 36.3804 161.318 36.4191 161.212C36.4579 161.106 36.4595 160.99 36.4237 160.883C36.388 160.776 36.317 160.685 36.2226 160.624C36.1919 160.601 36.1584 160.581 36.123 160.565Z"
          fill="#6C63FF"
        />
        <path
          opacity="0.5"
          d="M183.678 62.828C183.455 62.7294 183.276 62.5513 183.174 62.3275C183.072 62.1037 183.055 61.8499 183.126 61.6143C183.138 61.5783 183.145 61.5411 183.148 61.5035C183.164 61.3917 183.142 61.2777 183.085 61.1805C183.029 61.0833 182.941 61.0089 182.836 60.9696C182.731 60.9304 182.617 60.9288 182.511 60.965C182.405 61.0012 182.315 61.0731 182.256 61.1687C182.234 61.1992 182.215 61.2322 182.2 61.2671C182.103 61.4928 181.928 61.6747 181.707 61.7781C181.487 61.8816 181.237 61.8995 181.004 61.8284C180.968 61.8168 180.932 61.8094 180.895 61.8063C180.784 61.7897 180.672 61.8121 180.576 61.8696C180.48 61.9272 180.406 62.0164 180.367 62.1223C180.329 62.2282 180.327 62.3443 180.363 62.4513C180.399 62.5583 180.47 62.6495 180.564 62.7098C180.594 62.7324 180.627 62.7514 180.661 62.7664C180.884 62.865 181.064 63.0431 181.165 63.2669C181.267 63.4907 181.284 63.7445 181.213 63.9801C181.202 64.0161 181.194 64.0533 181.191 64.0909C181.175 64.2027 181.197 64.3167 181.254 64.4139C181.31 64.511 181.399 64.5855 181.503 64.6248C181.608 64.664 181.722 64.6656 181.828 64.6294C181.934 64.5932 182.024 64.5213 182.083 64.4258C182.106 64.3952 182.124 64.3622 182.139 64.3273C182.236 64.1015 182.412 63.9198 182.633 63.8168C182.854 63.7137 183.105 63.6965 183.338 63.7684C183.373 63.78 183.41 63.7875 183.447 63.7906C183.557 63.8071 183.67 63.7848 183.766 63.7272C183.862 63.6697 183.935 63.5805 183.974 63.4746C184.013 63.3687 184.014 63.2525 183.979 63.1456C183.943 63.0386 183.872 62.9473 183.778 62.8871C183.747 62.8634 183.713 62.8436 183.678 62.828Z"
          fill="#6C63FF"
        />
        <path
          opacity="0.5"
          d="M177 109.441C176.777 109.343 176.598 109.165 176.496 108.941C176.394 108.717 176.377 108.463 176.448 108.228C176.46 108.192 176.467 108.154 176.47 108.117C176.487 108.005 176.464 107.891 176.408 107.794C176.351 107.697 176.263 107.622 176.158 107.583C176.054 107.544 175.939 107.542 175.833 107.578C175.728 107.615 175.638 107.686 175.578 107.782C175.556 107.812 175.537 107.846 175.522 107.88C175.425 108.106 175.249 108.288 175.028 108.391C174.807 108.494 174.556 108.511 174.324 108.439C174.288 108.428 174.252 108.42 174.214 108.417C174.104 108.401 173.991 108.423 173.896 108.48C173.8 108.538 173.726 108.627 173.687 108.733C173.649 108.839 173.647 108.955 173.683 109.062C173.718 109.169 173.789 109.26 173.884 109.321C173.914 109.343 173.947 109.362 173.981 109.377C174.204 109.476 174.383 109.654 174.485 109.878C174.587 110.101 174.604 110.355 174.533 110.591C174.521 110.627 174.514 110.664 174.511 110.702C174.495 110.813 174.517 110.927 174.574 111.025C174.63 111.122 174.718 111.196 174.823 111.236C174.928 111.275 175.042 111.276 175.148 111.24C175.253 111.204 175.344 111.132 175.403 111.037C175.425 111.006 175.444 110.973 175.459 110.938C175.556 110.712 175.732 110.531 175.953 110.428C176.174 110.325 176.425 110.307 176.657 110.379C176.693 110.391 176.73 110.398 176.767 110.401C176.877 110.418 176.99 110.396 177.086 110.338C177.182 110.281 177.255 110.191 177.294 110.085C177.333 109.98 177.334 109.863 177.298 109.756C177.263 109.649 177.192 109.558 177.097 109.498C177.067 109.475 177.035 109.456 177 109.441Z"
          fill="#6C63FF"
        />
        <path
          opacity="0.5"
          d="M181.976 16.5462C181.753 16.4477 181.574 16.2696 181.472 16.0458C181.37 15.822 181.353 15.5682 181.424 15.3325C181.436 15.2966 181.443 15.2594 181.446 15.2217C181.463 15.11 181.441 14.996 181.384 14.8988C181.327 14.8016 181.239 14.7272 181.134 14.6879C181.03 14.6487 180.915 14.647 180.809 14.6833C180.704 14.7195 180.614 14.7914 180.554 14.8869C180.532 14.9174 180.513 14.9505 180.498 14.9854C180.401 15.2112 180.225 15.3929 180.004 15.4959C179.783 15.5989 179.533 15.6161 179.3 15.5442C179.264 15.5326 179.228 15.5252 179.19 15.5221C179.08 15.5055 178.968 15.5279 178.872 15.5854C178.776 15.643 178.702 15.7322 178.663 15.8381C178.625 15.944 178.623 16.0601 178.659 16.1671C178.695 16.2741 178.766 16.3653 178.86 16.4256C178.89 16.4482 178.923 16.4672 178.957 16.4822C179.18 16.5808 179.359 16.7589 179.461 16.9827C179.563 17.2065 179.58 17.4603 179.509 17.6959C179.497 17.7319 179.49 17.7691 179.487 17.8067C179.471 17.9184 179.493 18.0325 179.55 18.1297C179.606 18.2268 179.694 18.3013 179.799 18.3405C179.904 18.3798 180.018 18.3814 180.124 18.3452C180.23 18.309 180.32 18.2371 180.379 18.1415C180.401 18.111 180.42 18.078 180.435 18.0431C180.532 17.8173 180.708 17.6356 180.929 17.5326C181.15 17.4295 181.401 17.4123 181.634 17.4842C181.669 17.4958 181.706 17.5033 181.743 17.5064C181.853 17.5229 181.966 17.5005 182.062 17.443C182.158 17.3855 182.231 17.2963 182.27 17.1904C182.309 17.0845 182.31 16.9683 182.275 16.8613C182.239 16.7544 182.168 16.6631 182.073 16.6029C182.043 16.5802 182.011 16.5612 181.976 16.5462Z"
          fill="#6C63FF"
        />
        <path
          opacity="0.5"
          d="M197.587 137.684C197.364 137.586 197.185 137.408 197.083 137.184C196.981 136.96 196.964 136.706 197.035 136.471C197.047 136.435 197.054 136.398 197.057 136.36C197.074 136.248 197.051 136.134 196.995 136.037C196.938 135.94 196.85 135.865 196.745 135.826C196.641 135.787 196.526 135.785 196.42 135.821C196.315 135.858 196.225 135.93 196.165 136.025C196.143 136.056 196.124 136.089 196.109 136.124C196.012 136.349 195.836 136.531 195.615 136.634C195.394 136.737 195.143 136.754 194.911 136.682C194.875 136.671 194.838 136.663 194.801 136.66C194.691 136.644 194.578 136.666 194.482 136.724C194.386 136.781 194.313 136.87 194.274 136.976C194.235 137.082 194.234 137.198 194.27 137.305C194.305 137.412 194.376 137.504 194.471 137.564C194.501 137.586 194.533 137.605 194.568 137.62C194.791 137.719 194.97 137.897 195.072 138.121C195.174 138.345 195.191 138.598 195.12 138.834C195.108 138.87 195.101 138.907 195.098 138.945C195.082 139.057 195.104 139.171 195.16 139.268C195.217 139.365 195.305 139.439 195.41 139.479C195.514 139.518 195.629 139.52 195.735 139.483C195.84 139.447 195.931 139.375 195.99 139.28C196.012 139.249 196.031 139.216 196.046 139.181C196.143 138.955 196.319 138.774 196.54 138.671C196.761 138.568 197.012 138.55 197.244 138.622C197.28 138.634 197.317 138.641 197.354 138.645C197.464 138.661 197.577 138.639 197.673 138.581C197.769 138.524 197.842 138.434 197.881 138.329C197.92 138.223 197.921 138.106 197.885 138C197.85 137.893 197.779 137.801 197.684 137.741C197.654 137.718 197.622 137.699 197.587 137.684Z"
          fill="#6C63FF"
        />
        <path
          opacity="0.5"
          d="M1.61897 65.1025C2.5131 65.1025 3.23794 64.3684 3.23794 63.4629C3.23794 62.5573 2.5131 61.8232 1.61897 61.8232C0.724838 61.8232 0 62.5573 0 63.4629C0 64.3684 0.724838 65.1025 1.61897 65.1025Z"
          fill="#3AD29F"
        />
        <path
          opacity="0.5"
          d="M182.275 37.6054C183.169 37.6054 183.894 36.8713 183.894 35.9658C183.894 35.0603 183.169 34.3262 182.275 34.3262C181.381 34.3262 180.656 35.0603 180.656 35.9658C180.656 36.8713 181.381 37.6054 182.275 37.6054Z"
          fill="#3AD29F"
        />
        <path
          opacity="0.5"
          d="M108.641 99.3281C109.648 99.3281 110.464 98.5014 110.464 97.4817C110.464 96.4619 109.648 95.6353 108.641 95.6353C107.634 95.6353 106.818 96.4619 106.818 97.4817C106.818 98.5014 107.634 99.3281 108.641 99.3281Z"
          fill="#3AD29F"
        />
        <path
          opacity="0.5"
          d="M108.641 99.3281C109.648 99.3281 110.464 98.5014 110.464 97.4817C110.464 96.4619 109.648 95.6353 108.641 95.6353C107.634 95.6353 106.818 96.4619 106.818 97.4817C106.818 98.5014 107.634 99.3281 108.641 99.3281Z"
          fill="#3AD29F"
        />
        <path
          opacity="0.5"
          d="M202.384 86.9052C203.278 86.9052 204.003 86.1711 204.003 85.2656C204.003 84.3601 203.278 83.626 202.384 83.626C201.489 83.626 200.765 84.3601 200.765 85.2656C200.765 86.1711 201.489 86.9052 202.384 86.9052Z"
          fill="#3AD29F"
        />
        <path
          opacity="0.5"
          d="M71.5094 13.4839C72.5163 13.4839 73.3326 12.6572 73.3326 11.6374C73.3326 10.6177 72.5163 9.79102 71.5094 9.79102C70.5025 9.79102 69.6863 10.6177 69.6863 11.6374C69.6863 12.6572 70.5025 13.4839 71.5094 13.4839Z"
          fill="#61D8A9"
        />
        <path
          opacity="0.5"
          d="M175.386 154.625C176.28 154.625 177.005 153.891 177.005 152.985C177.005 152.08 176.28 151.346 175.386 151.346C174.492 151.346 173.767 152.08 173.767 152.985C173.767 153.891 174.492 154.625 175.386 154.625Z"
          fill="#3AD29F"
        />
        <path
          d="M74.5553 93.9316C73.5976 97.0804 75.0415 100.803 77.8443 102.46C79.0209 103.154 80.3749 103.504 81.5149 104.257C83.992 105.892 84.9692 109.021 85.7739 111.901C86.6976 115.212 87.6238 118.713 86.7778 122.044C86.2382 124.169 85.0106 126.047 84.3129 128.125C83.7294 129.865 83.5262 131.713 83.7173 133.541C83.8194 134.511 84.0577 135.511 84.714 136.235C85.662 137.269 87.1886 137.424 88.5597 137.672C91.4582 138.227 94.1856 139.47 96.518 141.299C98.8505 143.129 100.722 145.491 101.978 148.194"
          stroke="url(#paint0_linear_6790_1170)"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M74.8495 93.9316C73.8917 97.0804 75.3357 100.803 78.1385 102.46C79.3151 103.154 80.6691 103.504 81.8091 104.257C84.2862 105.892 85.2634 109.021 86.0681 111.901C86.9918 115.212 87.918 118.713 87.072 122.044C86.5324 124.169 85.3048 126.047 84.6071 128.125C84.0236 129.865 83.8204 131.713 84.0115 133.541C84.1136 134.511 84.3518 135.511 85.0082 136.235C85.9562 137.269 87.4828 137.424 88.8538 137.672C91.7524 138.227 94.4797 139.47 96.8122 141.299C99.1447 143.129 101.016 145.491 102.272 148.194"
          stroke="#575757"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M111.104 70.3955C108.741 73.7314 106.288 77.3184 106.086 81.4199C105.778 87.7199 110.754 92.8727 112.975 98.7665C113.938 101.363 114.374 104.129 114.257 106.9C114.139 109.671 113.471 112.389 112.292 114.892C111.208 117.162 109.662 119.417 109.861 121.931C109.976 123.464 110.734 124.86 111.344 126.271C112.842 129.806 113.495 133.649 113.252 137.488C113.008 141.328 111.874 145.054 109.942 148.366"
          stroke="url(#paint1_linear_6790_1170)"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M111.395 70.3955C109.035 73.7437 106.582 77.3208 106.376 81.4199C106.067 87.7199 111.043 92.8727 113.265 98.7665C114.228 101.363 114.663 104.129 114.546 106.9C114.429 109.671 113.761 112.389 112.582 114.892C111.497 117.162 109.951 119.417 110.151 121.931C110.265 123.464 111.023 124.86 111.634 126.271C113.131 129.806 113.785 133.649 113.541 137.488C113.297 141.328 112.163 145.054 110.231 148.366"
          stroke="#575757"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M144.256 100.401L145.399 109.833C145.549 111.086 145.678 112.452 145.015 113.526C144.385 114.538 143.204 115.02 142.285 115.769C139.268 118.23 139.543 122.935 140.583 126.712C141.624 130.488 143.121 134.545 141.675 138.179C141.077 139.679 140.022 140.942 139.244 142.35C137.909 144.756 137.38 147.536 137.737 150.272"
          stroke="url(#paint2_linear_6790_1170)"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <path
          d="M144.54 100.401L145.683 109.833C145.834 111.086 145.962 112.452 145.299 113.526C144.669 114.538 143.488 115.02 142.569 115.769C139.552 118.23 139.827 122.935 140.867 126.712C141.908 130.488 143.405 134.545 141.959 138.179C141.361 139.679 140.306 140.942 139.528 142.35C138.193 144.756 137.665 147.536 138.021 150.272"
          stroke="#575757"
          strokeWidth="2"
          strokeMiterlimit="10"
        />
        <g opacity="0.7">
          <path
            opacity="0.7"
            d="M173.881 61.6363C173.879 57.224 172.847 52.8747 170.867 48.9439C168.888 45.0131 166.019 41.6124 162.494 39.0196C158.968 36.4268 154.887 34.7156 150.584 34.0257C146.281 33.3358 141.878 33.6869 137.734 35.0502C137.962 33.4793 138.08 31.8939 138.087 30.3061C138.098 23.0596 135.329 16.092 130.365 10.8729C125.401 5.65388 118.629 2.59142 111.479 2.33148C104.328 2.07154 97.3572 4.63444 92.0365 9.47961C86.7157 14.3248 83.4611 21.0734 82.959 28.3021C78.8143 26.9359 74.4092 26.5826 70.1039 27.2713C65.7986 27.9599 61.7154 29.6709 58.1883 32.2643C54.6611 34.8577 51.7902 38.2598 49.8104 42.1924C47.8305 46.125 46.798 50.4764 46.7971 54.8907C46.7971 68.9235 57.0068 88.8649 70.2916 92.3978L72.3603 93.5943L71.2373 97.4914H77.8882L76.4442 93.5893L78.5032 92.3978C88.7761 89.6675 97.1991 77.134 100.498 65.1125C102.304 66.3299 104.292 67.2444 106.385 67.8206L108.454 69.0171L107.348 72.9044H113.999L112.555 69.0023L114.614 67.8108C116.14 67.395 117.61 66.7906 118.99 66.0111C120.767 79.4974 130.22 95.9626 142.183 99.1409L144.251 100.337L143.128 104.235H149.779L148.33 100.332L150.389 99.1409C163.684 95.6081 173.881 75.6593 173.881 61.6363Z"
            fill="url(#paint3_linear_6790_1170)"
          />
        </g>
        <path
          d="M78.9455 96.0487H72.5085L73.6365 92.1392H77.4967L78.9455 96.0487Z"
          fill="#3AD29F"
        />
        <path
          d="M102.272 54.8265C102.272 69.7653 90.3148 91.6492 75.5666 91.6492C60.8184 91.6492 48.8608 69.7629 48.8608 54.8265C48.8608 47.653 51.6746 40.7733 56.6831 35.7009C61.6916 30.6285 68.4847 27.7788 75.5678 27.7788C82.6509 27.7788 89.4439 30.6285 94.4524 35.7009C99.461 40.7733 102.275 47.653 102.275 54.8265H102.272Z"
          fill="#3AD29F"
        />
        <path
          d="M77.4967 92.3018H73.6365L71.3831 90.9995H79.7502L77.4967 92.3018Z"
          fill="#575757"
        />
        <path
          opacity="0.1"
          d="M65.9135 33.8066C65.9135 33.8066 54.6512 39.9983 53.042 52.3817L65.9135 33.8066Z"
          fill="white"
        />
        <path d="M113.87 72.2718H107.433L108.561 68.3623H112.421L113.87 72.2718Z" fill="#6C63FF" />
        <path
          d="M137.197 31.0496C137.197 45.9885 125.239 67.8723 110.491 67.8723C95.7427 67.8723 83.7852 45.986 83.7852 31.0496C83.7852 23.8762 86.5989 16.9965 91.6074 11.924C96.616 6.85161 103.409 4.00195 110.492 4.00195C117.575 4.00195 124.368 6.85161 129.377 11.924C134.385 16.9965 137.199 23.8762 137.199 31.0496H137.197Z"
          fill="#6C63FF"
        />
        <path
          d="M112.421 68.5245H108.561L106.307 67.2197H114.674L112.421 68.5245Z"
          fill="#575757"
        />
        <path
          opacity="0.1"
          d="M100.838 10.0298C100.838 10.0298 89.5758 16.2215 87.9666 28.6048L100.838 10.0298Z"
          fill="white"
        />
        <path d="M148.5 102.59H142.063L143.191 98.6782H147.052L148.5 102.59Z" fill="#3AD29F" />
        <path
          d="M171.827 61.3656C171.827 76.3044 159.87 98.1882 145.121 98.1882C130.373 98.1882 118.413 76.3044 118.413 61.3656C118.413 54.1921 121.227 47.3124 126.236 42.24C131.244 37.1675 138.037 34.3179 145.12 34.3179C152.203 34.3179 158.996 37.1675 164.005 42.24C169.013 47.3124 171.827 54.1921 171.827 61.3656Z"
          fill="#3AD29F"
        />
        <path
          d="M147.052 98.8404H143.191L140.938 97.5381H149.305L147.052 98.8404Z"
          fill="#575757"
        />
        <path
          opacity="0.1"
          d="M135.468 40.3457C135.468 40.3457 124.206 46.5374 122.597 58.9208L135.468 40.3457Z"
          fill="white"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M125.533 107.199C123.985 105.626 121.727 105 119.174 105.436C116.787 105.845 114.397 107.159 112.448 109.129C111.883 109.704 111.408 110.363 111.04 111.084C110.682 110.339 110.211 109.655 109.645 109.057C107.729 107.051 105.362 105.699 102.972 105.256C100.43 104.764 98.1615 105.362 96.5839 106.908C95.0062 108.454 94.3596 110.732 94.7534 113.321C95.1229 115.744 96.3821 118.184 98.3001 120.185C102.33 124.398 110.374 122.753 110.715 122.679L110.814 122.657L111.123 122.728C111.463 122.807 119.478 124.592 123.579 120.451C125.524 118.482 126.829 116.067 127.242 113.649C127.692 111.061 127.082 108.774 125.533 107.199ZM109.363 120.247C107.418 120.513 102.574 120.872 100.169 118.359C98.6234 116.741 97.6121 114.811 97.3229 112.918C97.0603 111.194 97.4395 109.717 98.3973 108.787C98.7711 108.423 99.2181 108.146 99.7076 107.974C100.612 107.678 101.578 107.629 102.508 107.831C104.365 108.183 106.242 109.267 107.788 110.884C110.192 113.381 109.686 118.285 109.363 120.244V120.247ZM124.704 113.203C124.383 115.089 123.341 117.004 121.765 118.592C119.318 121.054 114.473 120.619 112.54 120.316C112.251 118.346 111.828 113.45 114.276 110.978C115.851 109.39 117.745 108.339 119.607 108.023C120.54 107.837 121.505 107.903 122.405 108.216C122.892 108.396 123.335 108.682 123.703 109.053C124.641 110.005 124.996 111.478 124.704 113.201V113.203Z"
            fill="url(#paint4_linear_6790_1170)"
          />
        </g>
        <path
          d="M111.505 122.142L110.739 122.305C110.413 122.374 102.717 123.947 98.8591 119.917C97.0263 118.001 95.8205 115.668 95.4559 113.348C95.0767 110.869 95.699 108.691 97.2061 107.211C98.7133 105.731 100.884 105.17 103.317 105.63C105.595 106.061 107.861 107.354 109.694 109.272C113.549 113.302 111.755 121.044 111.677 121.372L111.505 122.142ZM100.194 108.235C99.7251 108.4 99.2971 108.665 98.9394 109.013C98.0253 109.912 97.6607 111.315 97.9111 112.967C98.1882 114.776 99.1557 116.625 100.636 118.174C102.938 120.579 107.574 120.234 109.434 119.981C109.742 118.107 110.228 113.425 107.926 111.02C106.446 109.473 104.652 108.435 102.873 108.1C101.983 107.906 101.059 107.952 100.194 108.235Z"
          fill="#6C63FF"
        />
        <path
          d="M110.367 122.177L110.197 121.404C110.124 121.074 108.461 113.302 112.385 109.341C114.252 107.457 116.537 106.204 118.822 105.813C121.253 105.394 123.423 105.992 124.899 107.499C126.374 109.006 126.965 111.192 126.545 113.654C126.151 115.966 124.906 118.277 123.039 120.161C119.116 124.122 111.446 122.413 111.128 122.34L110.367 122.177ZM121.914 108.457C121.054 108.158 120.132 108.095 119.24 108.272C117.456 108.577 115.645 109.584 114.135 111.103C111.792 113.469 112.19 118.157 112.475 120.038C114.329 120.323 118.96 120.749 121.301 118.386C122.808 116.864 123.805 115.033 124.114 113.216C124.393 111.569 124.053 110.158 123.154 109.245C122.801 108.895 122.378 108.626 121.914 108.457Z"
          fill="#6C63FF"
        />
        <g opacity="0.5">
          <path
            opacity="0.5"
            d="M147.903 120.106H73.272V135.23H77.1492V179H144.025V135.23H147.903V120.106Z"
            fill="url(#paint5_linear_6790_1170)"
          />
        </g>
        <path d="M143.179 124.678H77.9949V178.257H143.179V124.678Z" fill="#3AD29F" />
        <path d="M116.726 134.245H104.445V178.254H116.726V134.245Z" fill="#6C63FF" />
        <path opacity="0.1" d="M143.179 121.808H77.9949V135.203H143.179V121.808Z" fill="black" />
        <path d="M146.959 120.85H74.2173V134.245H146.959V120.85Z" fill="#3AD29F" />
        <path d="M116.726 120.85H104.445V134.245H116.726V120.85Z" fill="#6C63FF" />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_6790_1170"
          x1="74.0157"
          y1="121.069"
          x2="102.197"
          y2="121.069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_6790_1170"
          x1="5157.07"
          y1="41451.1"
          x2="5450.33"
          y2="41451.1"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_6790_1170"
          x1="6020.66"
          y1="29801.4"
          x2="6293.24"
          y2="29801.4"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_6790_1170"
          x1="80658.9"
          y1="51966.8"
          x2="80658.9"
          y2="9788.78"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_6790_1170"
          x1="20957.2"
          y1="10719.7"
          x2="20957.2"
          y2="9390.62"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_6790_1170"
          x1="34024.3"
          y1="42940.5"
          x2="34024.3"
          y2="28852"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#808080" stopOpacity="0.25" />
          <stop offset="0.54" stopColor="#808080" stopOpacity="0.12" />
          <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
        </linearGradient>
        <clipPath id="clip0_6790_1170">
          <rect width="204" height="179" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
