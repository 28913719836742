import { Modal, Card, CardContent, IconButton } from '@material-ui/core';
import { CloseIcon } from '@/components/Icons';
import clsx from 'clsx';
import { VacationForm } from '@/components/Forms';
import useStyles from '../styles';
import { IVacationModalProps } from '../types';

const VacationModal = ({
  open,
  closeModalHandler,
  onSubmitHandler,
  user,
  date,
  setDate,
}: IVacationModalProps): JSX.Element => {
  const classes = useStyles();

  const closeModal = () => {
    closeModalHandler(false);
    setDate([null, null]);
  };

  return (
    <Modal
      open={open}
      onClose={closeModal}
      className={clsx(classes.modalWrap, classes.nonOverflowedModal)}
      disableEnforceFocus
      disableAutoFocus
    >
      <Card className={clsx(classes.smallModalCard)}>
        <CardContent className={classes.smallModalCardContent}>
          <IconButton
            aria-label="delete"
            className={classes.closeIconWrap}
            size="small"
            onClick={closeModal}
          >
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
          <VacationForm
            closeModal={closeModal}
            onSubmitHandler={onSubmitHandler}
            date={date}
            setDate={setDate}
            user={user}
          />
        </CardContent>
      </Card>
    </Modal>
  );
};

export default VacationModal;
