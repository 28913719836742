import { Box, Chip, Typography } from '@material-ui/core';
import StatisticCard from '@/components/Cards/StatisticCard';
import clsx from 'clsx';
import { addDays, differenceInMonths, format } from 'date-fns';
import { ContractStatusEnum } from '@/types/contract';
import { useMemo } from 'react';
import useStyles from './styles';
import { IContractsCardProps } from './types';
import useContractsCard from './useContractsCard';

const ContractsCard = ({ className }: IContractsCardProps) => {
  const styles = useStyles();

  const {
    contractStatus,
    contractExpirationDate,
    upcomingContractStartDate,
    upcomingContractEndDate,
  } = useContractsCard();

  const isContractEndingSoon = useMemo(() => {
    if (!contractExpirationDate && contractStatus !== ContractStatusEnum.Active) {
      return false;
    }

    return differenceInMonths(new Date(contractExpirationDate as string), new Date()) <= 1;
  }, [contractExpirationDate, contractStatus]);

  const label = useMemo(() => {
    if (contractStatus) {
      return contractStatus;
    }

    if (!contractExpirationDate) {
      return 'Expired ';
    }

    return '';
  }, [contractExpirationDate, contractStatus]);

  return (
    <StatisticCard
      title="Contracts"
      badge={
        <Chip
          className={clsx(styles.statusChip, {
            [styles.statusChipError]: contractStatus !== ContractStatusEnum.Active,
            [styles.statusChipInfo]: isContractEndingSoon,
          })}
          label={label}
          color="primary"
        />
      }
      overtimeTitle={
        contractStatus === ContractStatusEnum.Closed ? (
          <Typography className={styles.closeCoopColor}>
            Cooperation is terminated from{' '}
            {contractExpirationDate
              ? format(addDays(new Date(contractExpirationDate), 1), 'dd.MM.yyyy')
              : null}
          </Typography>
        ) : (
          upcomingContractStartDate &&
          upcomingContractEndDate && (
            <Typography className={styles.upcomingColor}>
              Upcoming contract signed: {format(new Date(upcomingContractStartDate), 'dd.MM.yyyy')}{' '}
              - {format(new Date(upcomingContractEndDate), 'dd.MM.yyyy')}
            </Typography>
          )
        )
      }
      className={className}
    >
      {contractExpirationDate ? (
        <Box>
          <Typography>Contract expiration date:</Typography>
          <Typography component="span" className={styles.value}>
            {format(new Date(contractExpirationDate), 'LLLL d y')}
          </Typography>
        </Box>
      ) : (
        <Box className={styles.box}>
          <Typography className={styles.closeCoopColor}>No active contracts</Typography>
        </Box>
      )}
    </StatisticCard>
  );
};

export default ContractsCard;
