import { Divider } from '@material-ui/core';
import SimpleStatisticCard from '@/components/Cards/SimpleStatisticCard';
import useStyles from './styles';
import { ICombinedStatisticsCardProps } from './types';
import { HoursCard, BalanceCard, OvertimeCard } from './components';

const CombinedStatisticsCard = ({ user }: ICombinedStatisticsCardProps): JSX.Element => {
  const styles = useStyles();
  return (
    <SimpleStatisticCard contentClassName={styles.root}>
      <BalanceCard />
      <Divider className={styles.divider} />
      <HoursCard user={user} />
      <Divider className={styles.divider} />
      <OvertimeCard user={user} />
    </SimpleStatisticCard>
  );
};

export default CombinedStatisticsCard;
